import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    // Obté la preferència de l'usuari sobre el tema fosc
    this.darkMode(
      //*
      window.matchMedia("(prefers-color-scheme: dark)").matches
      /*/
      true
      //*/
    );

    // registra la icona SVG
    this.iconRegistry.addSvgIcon('useitIA', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/logo.svg'));
  }

  // Estableix el tema dark/light
  darkMode(enabled:boolean) {
    if (enabled) {
      document.body.classList.add("dark-theme")
    } else {
      document.body.classList.remove("dark-theme")
    }
  }

}
