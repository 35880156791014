import { Component } from '@angular/core';

@Component({
  selector: 'app-api-keys',
  standalone: true,
  imports: [],
  templateUrl: './api-keys.component.html',
  styleUrl: './api-keys.component.scss'
})
export class ApiKeysComponent {

}
