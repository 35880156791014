import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FreqVisualizerComponent } from './freq-visualizer/freq-visualizer.component';
//import { LoginComponent } from '../login/login.component';
import { Observable, of, delay, map, catchError, tap, lastValueFrom, switchMap, iif, share } from 'rxjs';

import { ApiService, IRankResponse, ISearchConfiguration } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

type IFollowUpOptions = Array<{num:number, text:string, query?:string, doctype?:string}>;

interface IInteractionResponse extends Partial<IRankResponse> {
  response: string,
  followUp?: IFollowUpOptions,
  feedback?: 'correct'|'non_evaluated'|'incorrect'
}

interface IInteraction extends Partial<IInteractionResponse> {
  request: string;
  response$: Observable<IInteractionResponse|never>;
}

const DEFAULT_QUESTIONS:IFollowUpOptions = [
  /*{num:1, text:"Vull sol·licitar un certificat d'empadronament."},
  {num:2, text:"Vull donar-me d'alta al Padró Municipal d'Habitants."},
  {num:3, text:"Vull canviar el meu domicili al Padró Municipal d'Habitants."},
  {num:4, text:"Vull realitzar un altre tràmit."},*/
];

// Inicio el SpeechRecognition al script 'src/assets/SpeechRecognitionAux.js',
// que el TypeScript no vol compilar-ho.
declare var sparkRecognition: any;


@Component({
  selector: 'spark-chat',
  templateUrl: './spark-chat.component.html',
  styleUrls: ['./spark-chat.component.scss']
})
export class SparkChatComponent implements OnInit, AfterViewInit {

  dark!:boolean; // <- tema fosc?
  availableQuestions = DEFAULT_QUESTIONS;

  @Input('uuid') selectedProjectUUID!: string;
  @Input('espai') selectedSpaceUUID!: string;
  configurations$!:Observable<ISearchConfiguration[]>; 

  messages: IInteraction[] = [];
  sparkRecognition:any;

  sidenavOpen: boolean = true;//false;

  @ViewChild('peticio', {static:true}) userInput!: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private api: ApiService,
  ) {
    // Obté la preferència de l'usuari sobre el tema fosc
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    this.darkMode(prefersDark.matches);

    this.setUp().catch(
      err=>console.log(`${this.constructor.name} setUp:`, err)
    );

    if (!!sparkRecognition) {
      sparkRecognition.continuous = true;
      sparkRecognition.lang = 'ca-ES';
      sparkRecognition.interimResults = false;
      sparkRecognition.maxAlternatives = 1;    
      this.sparkRecognition = sparkRecognition;
    }
  }

  // Inicia el backend quan calgui i obté valors preliminars
  async setUp() {
    // const projects = await lastValueFrom(this.api.listProjects());
    // this.availableProjects = projects.filter(p=>p.enabled);
    // // per defecte seleccionem el primer UUID disponible
    // if (!!this.availableProjects.length) {
    //   this.selectedProjectUUID = this.availableProjects[0].uuid;
    // }
  }

  ngOnInit(): void {
    this. configurations$ = this.api.searchConfigurations(this.selectedProjectUUID, this.selectedSpaceUUID);
  }

  ngAfterViewInit(): void {

    if (environment.production) {
      // // Mostra modal de Login:
      // const dialogRef = this.dialog.open(LoginComponent, {
      //   ariaModal: true,
      //   disableClose: true
      // });
      // // dialogRef.afterClosed().subscribe({
      // //   next:(resp)=>console.log('Login dialog closed', resp)
      // // });
    }
    

    if (!!sparkRecognition) {
      sparkRecognition.onresult = (evt:any)=> {
        const results: SpeechRecognitionResultList = evt.results; 
        // com que està en mode "continous, només conté 1 result"
        const result = results[0][0].transcript;
        this.userInput.nativeElement.value = result;
      }
      
      sparkRecognition.onerror = (evt:any) => {
        console.log(`${this.constructor.name} speech recognition:`,evt.error);
      }
    }

  }

  /*/ esbrina primer si hi ha múltiples documents relacionats amb una consulta
  // i fa primer una resposta de desambiguació si cal (p.ex: si hi ha més d'un document relacionat)
  // en cas contrari, retorna la query final.
  private buildQuery(request:string) {
    const uuid = this.selectedProjectUUID();
    const space_uuid = this.selectedSpaceUUID();
    const config = this.searchConfiguration();

    const needsFollowUp = (resp:IDocType[])=>{
      if (resp.length>1) {
        const scores = resp.map(e=>e.score);
        const max = Math.max(...scores);
        // la mitjana del valor dels scores que no son el màxim
        const meanrest = scores.reduce((a,b)=>a+b, -max)/(scores.length-1);
        return max < 1.5 * meanrest; // <- només cal followUp si el valor màxim no puntua força més que la mitjana de la resta.
      } else return false;
    }
    return this.api.queryTargetContent(uuid, request).pipe(
      switchMap((nxt)=>iif(
        ()=>needsFollowUp(nxt), 
        of({
          response:"Pots refinar la questió triant la més adient de les següents opcions?",
          followUp:nxt.map((e, i)=>{
            const dt = e.type;
            const retval:{num:number, text:string, query:string, doctype?:string} = { num: i+1, text:dt?e.title:request, query:request } ;
            if (!!dt) retval.doctype = dt;
            return retval;
          }) as IFollowUpOptions
        }),
        this.api.query(uuid, space_uuid, request, config)
      ))
    )
  }*/
  
  // Envia una questió "request" a l'agent Spark. Prèn un paràmetre addicional "greetOut" (per defecte fals)
  // per forçar la petició quan encara no hi ha missatges però ja ha finalitzat la animació inicial.
  sendQuestion(request:string, scfg:string, greetOut=false):void {

    if (!this.messages.length && !greetOut) {
      // Engega la animació del greeting abans d'enviar la petició
      ['.greeting', '.options'].map(qs=>document.querySelector(qs)).forEach(elem=>{
        elem?.classList.add('greet-out');
      })
      // efectua la petició en acabar la animació
      setTimeout(()=>this.sendQuestion(request, scfg, true), 1000);
      return;
    }

    // Objecte auxiliar que conté la petició i l'observable de resposta (api call),
    // i a més cacheja la pròpia resposta un cop s'ha obtingut.
    const interaction:IInteraction = {
      request: request, 
      response$: this.api.query(this.selectedProjectUUID, this.selectedSpaceUUID, request, scfg).pipe( 
        map((e)=>{ 
          if (!e.response||e.response=="Empty Response") throw(new Error("no tinc resposta"));
          let retval:IInteractionResponse = { response: e.response, query_id: e.query_id };
          if (Object.keys(e).includes('followUp')) retval = e;
          return retval; 
        }),
        catchError(err=>{
          interaction.response = `Ho sento, alguna cosa ha fallat (${err.message}). Prova-ho en un altre moment.`;
          interaction.followUp = this.availableQuestions;
          this.scrollChat();
          throw err;
        }),
        tap((resp:IInteractionResponse)=>{
          interaction.response=resp.response;
          interaction.query_id=resp.query_id;
          this.scrollChat();
        }),
      ) as Observable<IInteractionResponse>
    };

    this.messages.push(interaction);
    this.scrollChat();
  }

  // Assegura que el nou contingut al final del xat sempre queda a la vista 
  private scrollChat() {
    setTimeout(()=>{
      const chat = document.querySelector('.chat');
      chat?.scrollTo({top:chat?.scrollHeight, left:0, behavior:'smooth'});
    }, 1);
  }

  // Canvia el tema light/dark
  darkMode(enabled:boolean) {
    if (enabled) {
      document.body.classList.add("dark-theme")
    } else {
      document.body.classList.remove("dark-theme")
    }
    this.dark = enabled;
  }

  // Mostra visualització de l'audio
  showVisuals() {
    const cfg = new MatSnackBarConfig();
    cfg.panelClass = 'visualizer';
    cfg.data = { dark: this.dark }; 
    this.snackBar.openFromComponent( FreqVisualizerComponent, cfg );
  }

  // Amaga la visualització de l'audio
  hideVisuals() {
    this.snackBar.dismiss();
  }

  // Neteja la sessió de chat
  async resetChat() {
    this.messages = [];
    await lastValueFrom(this.api.resetChat());
  }

  feedback(how:'correct'|'incorrect'|'non_evaluated', resp:IInteraction ) {
    this.api.feedback(this.selectedProjectUUID, this.selectedSpaceUUID, resp.query_id!, how).pipe(
      this.api.uify('Un momento...', 'Enviando valoración')
    ).subscribe({
      next:(nxt)=>resp.feedback=how
    })
  }

}
