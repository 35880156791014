import { Component, Input, ChangeDetectionStrategy, Pipe, PipeTransform, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { environment } from 'src/environments/environment';

/// <reference types="aws-sdk" />
import * as AWS from 'aws-sdk';

import { ApiService } from '../api.service';
import { SourcesItem } from '../sources/sources-datasource';

@Pipe({
  name: 'sanitize',
  standalone: true
})
export class SanitizePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
  constructor(
    private sanitizer: DomSanitizer
  ) {}
}

@Pipe({
  name: 'fetchtxt',
  standalone: true
})
export class FetchTxtPipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    return this.getText(value)
  }

  async getText(url:string) {
    const resp  = await fetch(url);
    const text = await resp.text();
    return text;
  }
}

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'], 
  standalone: true,
  imports: [ CommonModule, NgxExtendedPdfViewerModule, SanitizePipe, FetchTxtPipe ],
  providers: [ NgxExtendedPdfViewerService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocViewerComponent implements OnInit {

  private _s3:AWS.S3;

  @Input() fileType: string = 'text/plain';
  @Input() source!:SourcesItem;

  signedURL!:string;


  /** In most cases, you don't need the NgxExtendedPdfViewerService. It allows you
   *  to use the "find" api, to extract text and images from a PDF file,
   *  to print programmatically, and to show or hide layers by a method call.
  */
  constructor(
    private pdfService: NgxExtendedPdfViewerService,
    private api: ApiService,
  ) {
    /* More likely than not you don't need to tweak the pdfDefaultOptions.
       They are a collecton of less frequently used options.
       To illustrate how they're used, here are two example settings: */
    pdfDefaultOptions.doubleTapZoomFactor = '150%'; // The default value is '200%'
    pdfDefaultOptions.maxCanvasPixels = 4096 * 4096 * 5; // The default value is 4096 * 4096 pixels,
    // but most devices support much higher resolutions.
    // Increasing this setting allows your users to use higher zoom factors,
    // trading image quality for performance.

    this._s3 = this.api.getS3client();
  }

  ngOnInit() {
    this.signedURL = this._s3.getSignedUrl('getObject', { Bucket: environment.s3bucket, Key: this.source.key, Expires:60});
    
    // debug
    // setTimeout(
    //   ()=>this.pdfService.getPageAsText(1).then(
    //     txt=>console.log(`DEBUG ${this.constructor.name} page 1 text`, txt)
    //   ),
    //   10000
    // );
  }

  debug(sth:any, tag="") {
    console.log(`DEBUG ${this.constructor.name}, ${tag}`, sth);
  }

}
