
<!-- Template que mostra les opcions predefinides del context en engegar o al final de cada interacció -->
<ng-template let-options="options" #options_t>
  <div class="options">
    <mat-chip-set class="mat-mdc-chip-set-stacked">
      @for (chip of options; track chip.num) {
        <mat-chip (click)="sendQuestion(chip.query?chip.query:chip.text, chip.doctype?chip.doctype:mrgCfg.value)">
          <span matChipAvatar>{{chip.num}}</span>
          <span class="text-color" style="white-space: normal;">{{chip.text}}</span>
          <mat-icon matChipTrailingIcon fontIcon="arrow_forward" style="color:var(--gray)"></mat-icon>
        </mat-chip>
      }
    </mat-chip-set>
  </div>
</ng-template>

<!-- Template que es mostra quan encara no hi ha cap interacció -->
<ng-template #greeting_t>
  <div class="greeting">
    <img src="assets/star.svg" class="spark rotating"/>
    Hola, soc Useit <span class="orange">IA</span><br/>
    <span class="gray">Quin tràmit vols realitzar?</span>
  </div>
  <ng-container *ngTemplateOutlet="options_t; context:{ options:availableQuestions }"></ng-container>
</ng-template>

<!-- Template per formatar les respostes de l'agent Spark -->
<ng-template let-response="response" let-followUp="followUp" #sparkResp_t>
  <div class="message {{response?'static':''}}">
    <img src="assets/star.svg" class="avatar"/>
    <div class="text-color">
      <b>Useit <span class="orange">IA</span></b><br/>
      @if (response) {
        <markdown [data]="response"></markdown>
      } @else {
        <div class="loading">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      }
    </div>
  </div>
  @if (followUp?.length) {
    <ng-container *ngTemplateOutlet="options_t; context:{ options:followUp }"></ng-container>
  }
</ng-template>

<!-- Aquest template mostra el llistat d'interaccions de l'usuari en format xat -->
<ng-template let-user="user" #chat_t>
  <div class="chat">
    <div class="fademask"></div>
    @for (interaction of messages; track interaction.request) {
      <div class="message">
        <div class="avatar">{{user}}</div>
        <div class="text-color">
          <b>Tu</b><br/>
          {{interaction.request}}
        </div>
      </div>
      @if (interaction.response) {
        <ng-container *ngTemplateOutlet="sparkResp_t; context:interaction"></ng-container>
      } @else if (interaction.response$ | async) {
        <ng-container *ngTemplateOutlet="sparkResp_t; context:interaction"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="sparkResp_t; context:interaction"></ng-container>
      }
    }
  </div>
</ng-template>

<!-- Frame de la aplicació -->
<mat-sidenav-container class="spark-frontend-container">
  
  <!-- Panell lateral. De moment mostra tabs amb: opcions de debug // LLMs Ollama -->
  <mat-sidenav mode="side" style="width:500px;" [opened]="sidenavOpen">
    <!-- <mat-tab-group headerPosition="below">
      <mat-tab label="Debug">
        <app-debug-panel 
          [peticio]="peticio.value" 
          [projectUUID]="selectedProjectUUID || ''"
          (projectChange)="selectedProjectUUID=$event;"></app-debug-panel>
      </mat-tab>
      <mat-tab label="Config">
        <app-config-panel [projectUUID]="selectedProjectUUID || ''"></app-config-panel>
      </mat-tab>
      <mat-tab label="Prompts">
        <app-prompts-panel [projectUUID]="selectedProjectUUID || ''"></app-prompts-panel>
      </mat-tab>
      <mat-tab label="LLMs">
        <app-llm-models></app-llm-models>
      </mat-tab>
    </mat-tab-group> -->

    <h4 style="text-align:center;margin-top:2em">
      <label id="cfg-radio-group-label">Elige la configuración de búsqueda:</label>
    </h4>
    <mat-radio-group style="display:flex;flex-direction:column;margin:15px 30px; align-items: flex-end;"
      aria-labelledby="cfg-radio-group-label" labelPosition="before" #mrgCfg="matRadioGroup">
      @if ( configurations$ | async; as configs) {
        @for (cfg of configs; track cfg) {
          <mat-radio-button style="margin:5px" [value]="cfg.uuid" [checked]="$first">{{cfg.name}}</mat-radio-button>
        }
      }
    </mat-radio-group>

  </mat-sidenav>

  <mat-sidenav-content>

    <mat-toolbar class="header" style="padding-top:16px">
      <img [src]="dark?'assets/useit_IA_small_white.png':'assets/useit_IA_small_black.png'" style="width:108px;margin:1em"/>
      
      <mat-chip-set>
        <mat-chip (click)="sidenavOpen=!sidenavOpen"><!-- TODO: menú de l'usuari (sessió) -->
          <span matChipAvatar>RM</span>
          <mat-icon matChipTrailingIcon fontIcon="expand_more" style="color:var(--gray)"></mat-icon>
        </mat-chip>
      </mat-chip-set>
      
      <!-- toggle auxiliar per canviar el tema light/dark (no hi és al disseny) -->
      <!-- <mat-slide-toggle [hideIcon]="true" [checked]="dark" (change)="darkMode($event.checked)">
        <span class="text-color">Tema fosc</span>
      </mat-slide-toggle> -->
    </mat-toolbar>

    <!-- Aquí es carrega el template adient dels definits anteriorment (greeting_t o chat_t) -->
    <ng-container *ngTemplateOutlet="!!messages.length? chat_t : greeting_t; context:{ user: 'RM' }"></ng-container>

    <!-- Per omplir l'espai sota el mat-toolbar.footer, i si apareix un scrollbar, que es porti bé -->
    <div style="height: 136px;"></div>

    <mat-toolbar class="footer" [class.narrower]="sidenavOpen" style="display:flex; flex-direction:column;">
      <mat-toolbar-row style="display:flex; justify-content: center;height: 72px;">
        <div style="width: 890px; display:flex; justify-content: space-between;margin-top:16px">
          
          <!-- input de l'usuari -->
          <mat-form-field style="flex-basis: 92%" class="custom">
            <input matInput 
              placeholder="Introdueix una petició aquí" 
              aria-label="Escriu aquí la teva petició" 
              (keyup.enter)="sendQuestion(peticio.value, mrgCfg.value); peticio.value='';"
              #peticio>
            @if (!!sparkRecognition) {
              <button matSuffix mat-icon-button color="accent" aria-label="Dicta la teva petició" 
                matTooltip="Prem mentre dictes la teva consulta al micròfon"
                matTooltipPosition="above"
                (mousedown)="sparkRecognition.start(); showVisuals();"
                (mouseup)="sparkRecognition.stop(); hideVisuals();">
                <mat-icon>mic</mat-icon>
              </button>
            }
          </mat-form-field>

          <button mat-fab color="primary" aria-label="Envia la petició" style="box-shadow: none;" 
            [disabled]="!peticio.value" (click)="sendQuestion(peticio.value, mrgCfg.value); peticio.value='';">
            <mat-icon>send</mat-icon>
          </button>

        </div>
      </mat-toolbar-row>
      <mat-toolbar-row style="display:flex; justify-content:center; color:var(--gray);">
        Useit IA pot mostrar informació imprecisa (incloses dades sobre persones), així que comprova les respostes
        @if (messages.length) {
          <button mat-flat-button (click)="resetChat()" color="accent" style="margin-left:1em">Nova conversa</button>
        }
      </mat-toolbar-row>
    </mat-toolbar>

  </mat-sidenav-content>
</mat-sidenav-container>

