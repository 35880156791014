<ng-template let-data #preview>
  <h3 mat-dialog-title>Previsualización</h3>
  <app-doc-viewer mat-dialog-content [source]="data.item"></app-doc-viewer>
  <div mat-dialog-actions style="display:flex;justify-content: flex-end;">
    <button mat-button mat-dialog-close color="primary">Cerrar</button>
  </div>
</ng-template>

<ng-template let-data #editsrc>
  <h3 mat-dialog-title>Editar "<span style="font-weight: 300;">{{data.item.name}}</span>"</h3>
  <div mat-dialog-content>
    <mat-tab-group (selectedTabChange)="showExtra=!$event.index">
      <mat-tab label="General">
        <form [formGroup]="sourceEditForm" #efm="ngForm" style="display:flex;flex-direction: column;margin-top:2em">
          <mat-form-field>
            <mat-label>name</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>topic</mat-label>
            <mat-select formControlName="topic">
              @for(t of topics; track t.key) {
                <mat-option [value]="t.key">{{t.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <ng-container [formGroup]="sourceEditForm.controls.metadata">
            <span style="display:inline-block;margin:0 .5em;">Metadatos:</span><br/>
    
            @for (mdKey of data.mdkeys; track mdKey) {
              @if (mdKey == 'type') {
                <mat-form-field>
                  <mat-label>{{mdKey}}</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="content">content</mat-option>
                    <mat-option value="questions">questions</mat-option>
                  </mat-select>
                </mat-form-field>
              } @else {
                <mat-form-field>
                  <mat-label>{{mdKey}}</mat-label>
                  <input matInput [formControlName]="mdKey">
                </mat-form-field>
              } 
            }
          </ng-container>
        </form>
      </mat-tab>
      <mat-tab label="Categorías">
        <app-category-tree *ngIf="!!categories" 
          [categories]="categories" 
          [options]="{ categorySelection:true }"
          [selection]="data.selection"
          (change)="setCategorySelection($event.detail)">
        </app-category-tree>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div mat-dialog-actions style="display:flex;justify-content: space-between; flex-wrap: wrap;">
    <ng-container *ngIf="showExtra; else spacer">
      <mat-form-field style="flex-basis: 100%;margin:0 1em">
        <mat-label>nueva clave (metadato)</mat-label>
        <input matInput #clau>
      </mat-form-field>
      <button mat-button [disabled]="!clau.value || clau.value=='category'" (click)="addKey(clau.value);data.mdkeys.push(clau.value);clau.value=''">
        <mat-icon>add</mat-icon>
        Crear clave
      </button>
    </ng-container>    
    <ng-template #spacer><span style="flex-basis:50%;flex-grow: 2;"></span></ng-template>
    <button mat-button [mat-dialog-close]="efm.value" color="primary" [disabled]="efm.invalid">Hecho</button>
  </div>
</ng-template>

<!-- template pel diàleg per eliminar fonts -->
<ng-template let-data #delsrc_confirm>
  <h3 mat-dialog-title>Confirme:</h3>
  <div mat-dialog-content style="display:flex;flex-direction:column;">
      ¿Quiere eliminar la fuente "{{data?.item.name}}"?
  </div>
  <div mat-dialog-actions style="display:flex;justify-content: space-between">
      <button mat-button mat-dialog-close>
          Cancelar
      </button>
      <button mat-button color="primary" tabindex="0"
          mat-dialog-close="delete" >
          De acuerdo
      </button>
  </div>
</ng-template>


<!-- <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
   style="background-color: transparent;color:var(--text-color);/*padding:0 32px;*/height: 100%;" #tabs>
  <mat-tab label="SOURCES"> -->
<mat-stepper #stepper (selectionChange)="connectCurrentDataSource($event)">

  <!-- ######################################### STEP 1: SOURCES ################################################# -->

  <mat-step> <!-- [stepControl]="firstFormGroup" -->
    <ng-template matStepLabel>SOURCES</ng-template>
    <div class="mat-elevation-z0" style="/*margin: 032px;*/padding: 24px;background-color: var(--background-color);">
      <mat-table class="full-width-table" matSort aria-label="Sources" style="background-color:  var(--background-color)" 
        #sourceSort="matSort" #sourceTable="matTable">
        
        <!-- Id (edit button column) -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef style="flex-basis: 150px; flex-grow:0"></mat-header-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis: 150px; flex-grow:0"></mat-footer-cell>
          <mat-cell *matCellDef="let row" style="flex-basis: 150px; flex-grow:0; justify-content: flex-start;">
            <button mat-icon-button (click)="$event.stopPropagation(); editSource(row)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); delSource(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Name</mat-footer-cell>
        </ng-container>

        <!-- search column -->
        <ng-container matColumnDef="search">
          <mat-header-cell *matHeaderCellDef style="flex-basis:calc(30%); flex-shrink: 2; border-bottom:none; padding: 1em 0;padding-left:0">
            <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 2 0;text-transform: none;">
              <mat-label>Search sources</mat-label>
              <mat-icon matTextPrefix style="position:relative;top:+.33em;">search</mat-icon>
              <input matInput placeholder="..." [formControl]="sourcesSearchControl" type="search">
            </mat-form-field>
          </mat-header-cell>
        </ng-container>

        <!-- topic filter -->
        <ng-container matColumnDef="topic-filter">
          <mat-header-cell *matHeaderCellDef style="flex-basis:10%; border-bottom:none; padding: 1em 0">
            <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
              <mat-label>Topic</mat-label>
              <mat-select [formControl]="sourceTopicSelection" multiple>
                  @for (filter of topics; track filter.key) {
                      <mat-option [value]="filter.key">{{filter.name}}</mat-option>
                  }
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
      </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef style="flex-basis: 10%; flex-grow:0">
            <span style="display:block;padding-left:54px">Type</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex-basis: 150px; flex-grow:0">

            <mat-checkbox [checked]="!!row.selected"> <!--formControlName="pepperoni"-->
              <mat-icon style="color:gray">
                {{row.type=='txt'?'description':row.type=='pdf'?'picture_as_pdf':'link'}}
              </mat-icon>
            </mat-checkbox>
            <button mat-icon-button (click)="$event.stopPropagation(); previewDoc(row)">
              <mat-icon>visibility</mat-icon>
            </button>
            
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis: 150px; flex-grow:0">
            <span style="display:block;padding-left:48px">Type</span>
          </mat-footer-cell>
        </ng-container>

        <!-- type filter -->
        <ng-container matColumnDef="type-filter">
          <mat-header-cell *matHeaderCellDef style="flex-basis:10%; border-bottom:none; padding: 1em 0">
            <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
              <mat-label>Type</mat-label>
                    <mat-select [formControl]="sourceTypeSelection" multiple>
                      @for (filter of sourceTypes; track filter) {
                        <mat-option [value]="filter">{{filter}}</mat-option>
                      }
                    </mat-select>
            </mat-form-field>
          </mat-header-cell>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex-basis: 120px; flex-grow:0">Date</mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex-basis: 120px; flex-grow:0">{{row.created_at | date:'shortDate':'':'es'}}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis: 120px; flex-grow:0">Date</mat-footer-cell>
        </ng-container>

        <!-- Update Column -->
        <ng-container matColumnDef="update">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex-basis: 120px; flex-grow:0">Update</mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex-basis: 120px; flex-grow:0">{{row.updated_at | date:'mediumDate':'':'es-ES'}}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis: 120px; flex-grow:0">Update</mat-footer-cell>
        </ng-container>

        <!-- Tags Column -->
        <ng-container matColumnDef="tags">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tags</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-chip-set aria-label="tags">
              @for (tag of row.metadata_filters; track tag) {
                <mat-chip>{{tag.value}}</mat-chip>
              }
            </mat-chip-set>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>Tags</mat-footer-cell>
        </ng-container>

        <!-- Tags filter -->
        <ng-container matColumnDef="tags-filter">
          <mat-header-cell *matHeaderCellDef style="flex-basis:10%; border-bottom:none; padding: 1em 0">
            <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
              <mat-label>Tags</mat-label>
                    <mat-select [formControl]="sourceTagSelection" multiple>
                      @for (tag of sourceTags; track tag) {
                        <mat-option [value]="tag">{{tag}}</mat-option>
                      }
                    </mat-select>
            </mat-form-field>
          </mat-header-cell>
        </ng-container>

        <!-- paginator -->
        <ng-container matColumnDef="paginator">
          <mat-header-cell *matHeaderCellDef style="flex-basis:30%;border-bottom:none; padding: 1em 0">
            <mat-paginator #sourcePaginator="matPaginator" style="background-color: transparent;"
                [length]="sourcesDataSource.data.length"
                [pageIndex]="0"
                [pageSize]="15"
                [pageSizeOptions]="[5, 10, 15, 20]"
                aria-label="Select page">
            </mat-paginator>
          </mat-header-cell>
        </ng-container>

        <!-- subir datos Nota: des d'aquest component no tenim l'estat de navegació, per lo que el resolver cridarà l'API en aquest routerLink -->
        <ng-container matColumnDef="upload">
          <mat-header-cell *matHeaderCellDef style="flex-basis:10%; border-bottom:none; padding: 1em 0">
            <button mat-flat-button color="primary" *ngIf="!!espai" [routerLink]="['..', 'upload']"
              style="height: 54px; border-radius:27px; padding: 0 1.5em; flex:auto 0 0">
                <mat-icon>upload</mat-icon>
                Subir datos
            </button>
          </mat-header-cell>
          <!-- <mat-footer-cell *matFooterCellDef style="flex-basis:100%; border-top:none; padding: 1em 0">
            <app-data-upload [uuid]="uuid||''" [detail]="detail" ></app-data-upload>
          </mat-footer-cell> -->
        </ng-container>
        

        <!-- Lang Column -->
        <ng-container matColumnDef="lang">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex-basis: 100px; flex-grow:0">Lang</mat-header-cell>
          <mat-cell *matCellDef="let row" style="text-transform: uppercase;flex-basis:100px; flex-grow:0">{{ row.language }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis: 100px; flex-grow:0">Lang</mat-footer-cell>
        </ng-container>

        <!-- Topic Column -->
        <ng-container matColumnDef="topic">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex-basis: 100px;flex-grow:0;">Topic</mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex-basis:100px; flex-grow:0">{{ !!row.topic?.key?topicNames[row.topic.key]:'' }}</mat-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis:100px; flex-grow: 0;">Topic</mat-footer-cell>
        </ng-container>

        <!-- Indexed Column -->
        <ng-container matColumnDef="indexed">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex-basis: 100px; flex-grow:0">Indexed</mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex-basis: 100px; flex-grow:0">
            <mat-icon style="color:gray;font-size:14px;line-height: 1.8em;">{{row.state=='INDEXED'?'task_alt':'cancel'}}</mat-icon> {{row.state=='INDEXED'?'Yes':'No'}}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef style="flex-basis: 100px; flex-grow:0">Indexed</mat-footer-cell>
        </ng-container>
    
        <mat-header-row 
          *matHeaderRowDef="['search', 'type-filter', 'tags-filter', 'topic-filter', 'paginator', 'upload'].concat(displayedSourceColumns); sticky: true;" 
          style="text-transform: uppercase;background-color: var(--background-color);flex-wrap:wrap;">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedSourceColumns;"> 
          <!--(click)="row.selected=!row.selected;debug(row, 'row clicked')" --><!--- matRipple --> <!--[routerLink]="[row.uuid]" [state]="{ source: row }"-->
        </mat-row>
        <mat-footer-row *matFooterRowDef="displayedSourceColumns; sticky: true;"
          style="text-transform: uppercase;background-color: var(--background-color);flex-wrap:wrap;align-content: end;">
        </mat-footer-row>
      </mat-table>
    </div>
    <!-- <app-data-upload [uuid]="uuid||''" [detail]="detail" ></app-data-upload> -->
  </mat-step>


  <!-- ############################################################### STEP: REFINERY (CATEGORIES) ####################################################### -->

  <!-- <mat-step>
    <ng-template matStepLabel>CATEGORIES</ng-template>
    <div class="mat-elevation-z0" style="padding: 24px;background-color: var(--background-color);">
      <app-category-tree *ngIf="!!categories.length" [categories]="categories" [configs]="searchConfigs"></app-category-tree>
    </div>
  </mat-step> -->

</mat-stepper>

  <!-- </mat-tab>
  <mat-tab label="REFINERY">
    <p>Refinery</p>
  </mat-tab>
</mat-tab-group> -->



