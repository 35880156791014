import { ModuleWithProviders, NgModule, inject, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';

import { SparkChatComponent } from './spark-chat.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';


import { FreqVisualizerComponent } from './freq-visualizer/freq-visualizer.component';

@NgModule({
  declarations: [
    SparkChatComponent
  ],
  imports: [
    CommonModule,
    //FormsModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    MatSidenavModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTabsModule,
    MatDialogModule,
    MatRadioModule,
    FreqVisualizerComponent
  ],
  exports: [ SparkChatComponent ]
})
export class SparkChatComponentModule {}
