import { Component, Input } from '@angular/core';
import { ISpace, ApiService } from 'src/app/api.service';
import { getLanguageNameWithLocale } from 'src/app/domain/IndexingLanguages';

@Component({
  selector: 'app-space-detail',
  templateUrl: './space-detail.component.html',
  styleUrl: './space-detail.component.scss'
})
export class SpaceDetailComponent {

  @Input() uuid?: string;
  @Input() espai?:string;
  @Input() detail?:ISpace
  @Input() userRole?:string;

  expandCard: boolean = false;

  constructor(private api: ApiService) {

  }

  ngOnInit() {
  }

  getLanguageName(locale: string) {
    return getLanguageNameWithLocale(locale);
  }

  userHasAdminRights() {
    return this.api.userHasAdminRights();
  }

}
