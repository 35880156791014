import { Component, ElementRef, AfterViewInit, NgZone, Input, booleanAttribute, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-freq-visualizer',
  standalone: true,
  imports:[ MatSnackBarModule ],
  templateUrl: './freq-visualizer.component.html',
  styleUrl: './freq-visualizer.component.scss'
})
export class FreqVisualizerComponent implements AfterViewInit {

  canvas!: HTMLCanvasElement;
  canvasCtx!: CanvasRenderingContext2D;

  audioCtx: AudioContext;
  analyser: AnalyserNode;

  @Input({ transform: booleanAttribute }) dark:boolean = false;

  constructor(
    private ref:ElementRef,
    private zone: NgZone,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.audioCtx = new AudioContext();
    this.analyser = this.audioCtx.createAnalyser();
    this.analyser.minDecibels = -90;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const source = this.audioCtx.createMediaStreamSource(stream);
      source.connect(this.analyser);
      this.analyser.connect(this.audioCtx.destination);
      this.visualize();
    }).catch(function (err) {
      console.error(`FreqVisualizerComponent getUserMedia:`, err);
    });
  }

  ngAfterViewInit(): void {
    if (this.data) {
      this.dark = this.data.dark;
    }
    this.canvas = this.ref.nativeElement.firstElementChild;
    this.canvasCtx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  private visualize() {
    const WIDTH = this.canvas.width;
    const HEIGHT = this.canvas.height;

    this.analyser.fftSize = 256;
    const bufferLengthAlt = this.analyser.frequencyBinCount;
    const dataArrayAlt = new Uint8Array(bufferLengthAlt);

    this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    const drawAlt = () => {
      const drawVisual = requestAnimationFrame(drawAlt);

      this.analyser.getByteFrequencyData(dataArrayAlt);
      this.canvasCtx.fillStyle = this.dark?"#2c2c2c":'whitesmoke';
      this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      const barWidth = (WIDTH / bufferLengthAlt) * 2.5;
      let x = 0;

      for (let i = 0; i < bufferLengthAlt; i++) {
        const barHeight = dataArrayAlt[i];

        this.canvasCtx.fillStyle = "rgb(50," + (barHeight + 100) + ",50)";
        this.canvasCtx.fillRect( x, HEIGHT - barHeight / 2, barWidth, barHeight / 2 );

        x += barWidth + 1;
      }
    };

    this.zone.runOutsideAngular(()=>drawAlt());
  }

}
