import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { IStamped, ISpace, ITopic } from 'src/app/api.service'

export interface SourcesItem extends IStamped {
  key: string, // "dev/6f65f5f0-3b5e-4901-9ec5-de14f5ee9860.pdf",
  last_indexed?: string, // "Fri, 21 Jun 2024 11:06:26 GMT",
  name: string,
  metadata_filters: Array<{key:string, value:string}>,
  space: ISpace,
  state: string, // "INDEXED",
  type: string, // "pdf",
  topic?: ITopic,
  url?: string, // "https://s3.eu-west-1.amazonaws.com/useit.public/dev/6f65f5f0-3b5e-4901-9ec5-de14f5ee9860.pdf",
  uuid?: string // "10e1a5d4-df05-429c-ad30-571b4c602aba",
  language?: string
}

/**
 * Data source for the Sources view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SourcesDataSource extends DataSource<SourcesItem> {
  private _data$ = new BehaviorSubject<SourcesItem[]>([]); // EXAMPLE_DATA
  get data$() {
    return this._data$.asObservable();
  }
  set data(dd:SourcesItem[]) {
    this._data$.next(dd);
    if (!!this.paginator) {
      const opts = [5, 10, 15, 20];
      if (dd.length > opts[opts.length-1]) opts.push(dd.length)
      this.paginator.pageSizeOptions = opts;
    }
  }
  get data() {
    return this._data$.getValue()
  }
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor() {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<SourcesItem[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(this.data$, this.paginator.page, this.sort.sortChange)
        .pipe(map(() => {
          return this.getPagedData(this.getSortedData([...this._data$.value ]));
        }));
    } else if (this.sort) {
      // Si no tenim paginator...
      return merge(this.data$, this.sort.sortChange)
        .pipe(map(() => {
          return this.getSortedData([...this._data$.value ]);
        }));
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: SourcesItem[]): SourcesItem[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: SourcesItem[]): SourcesItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'lang': return compare(`${a.language}`, `${b.language}`, isAsc);
        case 'topic': return compare(`${a.topic}`,`${b.topic}`, isAsc);
        case 'date':
        case 'update':
          const da = (this.sort?.active)=='date' ? a.created_at : a.updated_at || a.created_at
          const db = (this.sort?.active)=='date' ? b.created_at : b.updated_at || b.created_at
          const dval = (val:string)=>new Date(val).getTime()
          return compare( dval(da), dval(db), isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
