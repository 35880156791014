import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { NavFrameComponent } from './nav-frame/nav-frame.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';

import { ProjectsComponent } from './projects/projects.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { ProjectDetailComponent } from './projects/project-detail/project-detail.component';
import { SpaceDetailComponent } from './projects/project-detail/space-detail/space-detail.component';

import { UsersComponent } from './users/users.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { SourcesComponent } from './sources/sources.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { SparkChatComponentModule } from './spark-chat-component/spark-chat.module';
import { SparkChatComponent } from './spark-chat-component/spark-chat.component';
import { MatTreeModule } from '@angular/material/tree';

import { CategoryTreeComponent } from './category-tree/category-tree.component';
import { QuestionEditorComponent } from './question-editor/question-editor.component';
import { TopicEditorComponent } from './topic-editor/topic-editor.component';
import { MatDividerModule } from '@angular/material/divider';
import { ModelDataComponent } from './projects/project-detail/space-detail/model-data/model-data.component';
import { ProjectSettingsComponent } from './projects/project-detail/project-settings/project-settings.component';
import { HotTopicsComponent } from './projects/project-detail/space-detail/hot-topics/hot-topics.component';



// per fer servir el locale als AngularPipes
import '@angular/common/locales/global/es';
import { SpaceConfigurationComponent } from './projects/project-detail/space-detail/space-configuration/space-configuration.component';
import { PromptsComponent } from './projects/project-detail/space-detail/prompts/prompts.component';
import { SessionsComponent } from './projects/project-detail/space-detail/sessions/sessions.component';
import { SessionDetailComponent } from './projects/project-detail/space-detail/sessions/session-detail/session-detail.component';
import { MatTableExporterModule } from 'mat-table-exporter';


@NgModule({
  declarations: [
    AppComponent,
    NavFrameComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    SpaceDetailComponent,
    UsersComponent,
    SourcesComponent,
    CategoryTreeComponent,
    QuestionEditorComponent,
    ModelDataComponent,
    TopicEditorComponent,
    SpaceConfigurationComponent,
    PromptsComponent,
    ProjectSettingsComponent,
    HotTopicsComponent,
    SessionsComponent,
    SessionDetailComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatChipsModule,
    MatRippleModule,
    MatStepperModule,
    MatCheckboxModule,
    DocViewerComponent,
    DataUploadComponent,
    SparkChatComponentModule,
    MatTreeModule,
    MatDividerModule,
    FormsModule,
    MatTableExporterModule,
  ],
  providers: [
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
