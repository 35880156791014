import { Component, Input, ViewChild } from '@angular/core';

import { ApiService, ISpace, ITopic, ICategoryNode, ISearchConfiguration, IQuestion } from 'src/app/api.service';
import { forkJoin } from 'rxjs';

import { environment } from 'src/environments/environment';

interface IBareTopic extends Partial<ITopic> {
  key:string;
  name:string;
  uuid:string;
}

@Component({
  selector: 'app-model-data',
  templateUrl: './model-data.component.html',
  styleUrl: './model-data.component.scss'
})
export class ModelDataComponent {

  private _espai!: ISpace;
  private _topics: ITopic[] = [];

  public topics?: IBareTopic[];
  public topicNames: {[key:string]:string} = {}; // auxiliar
  public categories!: ICategoryNode[];
  public searchConfigs: ISearchConfiguration[] = [];
  public questions?: IQuestion[];


  @Input() uuid?: string; // <- uuid del projecte (param de navegació)
  @Input() espai?: string; //<- uuid de l'espai (param de navegació)
  @Input() set detail(esp:ISpace) {
    if (!esp || !this.uuid) return;
    this._espai = esp;
    forkJoin({
      topics: this.api.espaiTopics(this.uuid, esp.uuid),
      categories: this.api.espaiCategories(this.uuid, esp.uuid),
      search_cfg: this.api.espaiSearchConfig(this.uuid, esp.uuid),
      questions: this.api.espaiQuestions(this.uuid, esp.uuid)
    }).pipe(
      this.api.uify( esp.name, "Obteniendo datos...." )
    ).subscribe({
      next: ({ topics, categories, search_cfg, questions }) => {
        this._topics = topics.filter(t=>!t.deleted);
        this._topics.forEach(t=>this.topicNames[t.key]=t.name);
        this.topics = this._topics.map(t=>{ return {key: t.key, name:t.name, uuid:t.uuid } });
        this.questions = questions.filter(q=>!q.deleted);
        this.searchConfigs = search_cfg.filter(s=>!s.deleted);
        this.categories = categories;

        // this.debug(this._topics, 'got topics:');
        // this.debug(this.categories, 'got tree of categories:');
        // this.debug(this.searchConfigs, 'got search config');
        // this.debug(this.questions, 'got questions:')
      }
    });
  }
  get detail() {
    return this._espai;
  }

  constructor(
    private api: ApiService,
  ) {}

  debug(sth:any, tag="") {
    if (environment.production) return; // <- No mostrar a producció
    console.log(`DEBUG ${this.constructor.name}, ${tag}`, sth);
  }

}
