import { Injectable } from '@angular/core';
import { ISubMenu, IMenuOption, IBreadCrumb } from './nav-frame.component';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SignalService {

  private _title = new Subject<string>();
  get title$() {
    return this._title.asObservable();
  }
  set title(tit: string) {
    this._title.next(tit);
  }

  private _subtitle = new Subject<string>();
  get subtitle$() {
    return this._subtitle.asObservable();
  }
  set subtitle(subt:string) {
    this._subtitle.next(subt);
  }

  private _backLinks = new Subject<IBreadCrumb[]>();
  get backLinks$() {
    return this._backLinks.asObservable();
  }
  set backLinks(option:IBreadCrumb[]) {
    this._backLinks.next(option);
  }

  private _subMenu = new Subject<ISubMenu>();
  get subMenu$() {
    return this._subMenu.asObservable();
  }
  set subMenu(menu:ISubMenu) {
    this._subMenu.next(menu);
  }

  constructor() { }
}
