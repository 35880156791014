import { Component, Directive, HostListener, HostBinding, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule, MatCheckbox } from '@angular/material/checkbox';

import { ApiService, ISpace } from '../api.service';
import { Subject, of, iif, map, switchMap, throwError, tap, startWith } from 'rxjs';

const SIZE_LIMIT_MB = 5;
const ALLOWED_TYPES = [
  "application/pdf",
  "text/plain",
  "text/uri-list"
];


@Directive({
  selector: '[file-drop]',
  exportAs: 'fileDrop',
  standalone: true
})
export class FileDropDirective {

  @Output('filesDropped') 
  filesDropped = new EventEmitter<Array<File|string>>();
  
  @HostBinding('class.drop-zone-active')
  active = false;

  @HostListener('dragenter', ['$event'])
  onDragEnter(evt:DragEvent) {
    evt.preventDefault();
    return true;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt:DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!evt.dataTransfer) return false;
    evt.dataTransfer.dropEffect = "copy";
    this.active = true;
    return true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    this.active = false;
  }

  @HostListener('drop', ['$event'])
  async onDrop(evt:DragEvent) {
    if (!evt) return;

    evt.preventDefault();
    this.active = false;

    const { dataTransfer } = evt;
    const files:(File | string)[] = [];
    if (dataTransfer?.items) {
      for (let i = 0; i < dataTransfer.items.length; i++) {
        // If dropped items are a files or directory
        if (dataTransfer.items[i].kind === 'file') {
          const item = dataTransfer.items[i]
          const f = item.getAsFile();
          const u = item.webkitGetAsEntry();
          if (u?.isDirectory) {
            const d = u as FileSystemDirectoryEntry;
            for await (const ff of this.getFilesRecursively(d)) {
              files.push(ff);
            }
          } else if (!!f) files.push(f);
        } else {
          const item = dataTransfer.items[i];
          if (item.type == 'text/uri-list') {
            const urls = await new Promise<string>((resolve)=>item.getAsString(resolve));
            files.push(urls);
          } // else console.log(`DEBUG ${this.constructor.name} excluding`, item)
        }
      }
      dataTransfer.items.clear();
      this.filesDropped.emit( files );
    } else {
      const filelist = dataTransfer?.files;
      for (let i = 0; i<(filelist?.length||0); i++){
        const f = filelist?.item(i);
        if (!!f) files.push(f)
      }
      dataTransfer?.clearData();
      this.filesDropped.emit(files);
    }
  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer)
      event.dataTransfer.dropEffect = "none";
  }
  @HostListener('body:drop', ['$event'])
  onBodyDrop(event: DragEvent) {
    event.preventDefault();
  }

  private async * getFilesRecursively(entry:FileSystemDirectoryEntry): AsyncGenerator<File> {
    const directoryReader = entry.createReader();
    const opts = { create: false };
    const entries = await new Promise<FileSystemEntry[]>((resolve, reject)=>directoryReader.readEntries(resolve, reject));
    for await (const e of entries) {
      if (e.isFile) {
        const file = await new Promise<File>((resolve,reject)=>entry.getFile(
          e.fullPath, opts, (fse)=>(<FileSystemFileEntry>fse).file(resolve, reject), reject
        ));
        if (!!file) {
          yield file;
        }
      } else if (e.isDirectory) {
        const dir = await new Promise<FileSystemDirectoryEntry>((resolve, reject)=>entry.getDirectory(
          e.fullPath, opts, (fse)=>resolve(<FileSystemDirectoryEntry>fse), reject
        ));
        for await (const handle of this.getFilesRecursively(dir)) {
          yield handle;
        }
      }
    }
  }

}

@Component({
  selector: 'app-data-upload',
  standalone: true,
  imports: [ 
    CommonModule, FileDropDirective, MatIconModule,
    MatCheckboxModule
  ],
  templateUrl: './data-upload.component.html',
  styleUrl: './data-upload.component.scss',
})
export class DataUploadComponent {

  @Input()
  detail?: ISpace

  @Input()
  uuid!: string

  keyvals: { [key:string]: string } = { "metadata_type": "content" }; // <- excepcionalment afegim el prefix 'metadata_' (només aquest endpoint).

  kvPairs(obj:{[key:string]: string}): string[][] {
    if (!obj) return [];
    return Object.keys(obj).map(key=>[key, obj[key]]);
  }

  @ViewChild(MatCheckbox, {static:true}) indexByDflt!:MatCheckbox;
  

  constructor(
    private api: ApiService,
  ) {}

  private toBlob( fileOrURL: File | string ) {
    const fob: File|Blob = typeof(fileOrURL) == "string" ? new Blob([fileOrURL], {type:"text/plain"}) : fileOrURL;
    const name: string = (<any>fileOrURL).name || fileOrURL;
    const bytesPerMB = 1024 * 1024;
    if (fob.size / bytesPerMB > SIZE_LIMIT_MB) {
      throw  new Error(`El documento "${name}" (${
        (fob.size / bytesPerMB).toFixed(1)
        } MB) supera el límite de ${SIZE_LIMIT_MB} MB`);
    }
    if (!ALLOWED_TYPES.includes(fob.type)) {
      throw new Error(`El tipo del documento "${name}" (${fob.type}) no está en la lista de tipos admitidos: ${ALLOWED_TYPES.join(', ')}.`);
    }
    return fob;
  }

  upload(filesOrURLs: (File|string)[]) {
    this.debug(filesOrURLs, 'upload');
    let count = 0;
    const docName = (sending: File | string)=>`"${(<File>sending).name || sending}"`;
    let messages = new Subject<string>();
    return iif(
      ()=>!!this.detail?.id,
      of(filesOrURLs).pipe(
        map(fous=>fous.map(fou=>this.toBlob(fou))),
        switchMap(dataUrls=>this.api.uploadBulk(this.uuid, `${this.detail?.uuid}`, dataUrls, this.indexByDflt.checked?this.keyvals:{})),
      ),
      throwError(()=>new Error('No hi ha identificador disponible!'))
    ).pipe(
      this.api.uify("Enviando documentos...", messages.pipe(startWith(docName(filesOrURLs[count++]))), 'Cancelar')
    ).subscribe({
      next: results=>{
        this.debug(results, 'upload returns');
        if (count < filesOrURLs.length) 
          messages.next(docName(filesOrURLs[count++]));
      }
    });
  }

  debug(sth:any, tag="") {
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth);
  }

}
