<mat-list style="background-color: var(--lighter-bgcolor);color:var(--text-color); display:flex; padding:24px">
  <mat-list-item>
    <span matListItemTitle>SESSIONS TOTALS</span>
    <span matListItemLine>{{sessionsTotals}}</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>MISSATGES TOTALS</span>
    <span matListItemLine>{{missatgesTotals}}</span>
  </mat-list-item>
</mat-list>

<mat-table matSort #sTable="matTable" #sSort="matSort" aria-label="Sessions"
  class="full-width-table" style="background-color:  var(--background-color); padding:24px">

  <!-- search input -->
  <ng-container matColumnDef="search">
    <mat-header-cell *matHeaderCellDef style="flex-basis:calc(40%); flex-shrink: 2; border-bottom:none; padding: 1em 0;padding-left:0">
      <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 2 0;text-transform: none;">
        <mat-label>Search session</mat-label>
        <mat-icon matTextPrefix style="position:relative;top:+.33em;">search</mat-icon>
        <input matInput placeholder="..." [formControl]="searchControl" type="search">
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <!-- topic filter -->
  <ng-container matColumnDef="topic-filter">
    <mat-header-cell *matHeaderCellDef style="flex-basis:20%; border-bottom:none; padding: 1em 0">
      <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
        <mat-label>Topic</mat-label>
        <mat-select [formControl]="topicSelection" multiple>
            @for (filter of topics; track filter) {
                <mat-option [value]="filter">{{filter}}</mat-option>
            }
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
  </ng-container>

  <!-- paginator -->
  <ng-container matColumnDef="paginator">
    <mat-header-cell *matHeaderCellDef style="flex-basis:40%;border-bottom:none; padding: 1em 0">
      <mat-paginator #sourcePaginator="matPaginator" style="background-color: transparent;"
          [length]="dataSource.data.length"
          [pageIndex]="0"
          [pageSize]="15"
          [pageSizeOptions]="[5, 10, 15, 20]"
          aria-label="Select page">
      </mat-paginator>
    </mat-header-cell>
  </ng-container>

  <!-- Session Column -->
  <ng-container matColumnDef="sessio">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Session</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.session}}</mat-cell>
    <mat-footer-cell *matFooterCellDef>Session</mat-footer-cell>
  </ng-container>

  <!-- Date Column -->
  <ng-container matColumnDef="data">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Inicio</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.start_date | date:'mediumDate':'':'es-ES' }}</mat-cell>
    <mat-footer-cell *matFooterCellDef>Inicio</mat-footer-cell>
  </ng-container>

  <!-- Messages Column -->
  <ng-container matColumnDef="missatges">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Mensajes</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.messages}}</mat-cell>
    <mat-footer-cell *matFooterCellDef>Mensajes</mat-footer-cell>
  </ng-container>

  <!-- Tags Column -->
  <ng-container matColumnDef="tags">
    <mat-header-cell *matHeaderCellDef>Tags</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-chip-set aria-label="tags">
        @if (row.topics.length >= 5) {
          @for (tag of row.topics.slice(0,4); track tag) {
            <mat-chip class="colored-chip">{{tag}}</mat-chip>
          }
          <mat-chip class="colored-chip">...</mat-chip>
        }
        @else {
          @for (tag of row.topics.slice(0,4); track tag) {
            <mat-chip class="colored-chip">{{tag}}</mat-chip>
          }
        }
        
      </mat-chip-set>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>Tags</mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['search', 'topic-filter', 'paginator'].concat(displayedColumns); sticky: true;" 
    style="text-transform: uppercase;background-color: var(--background-color);flex-wrap:wrap;">
  </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"
    (click)="row.selected=!row.selected;" matRipple [routerLink]="[row.session, row.timestamp]"><!-- [state]="{ session: row }"-->
  </mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;"
    style="text-transform: uppercase;background-color: var(--background-color);">
  </mat-footer-row>

</mat-table>



