import { 
  Component, Input, Output, OnInit, EventEmitter,
  WritableSignal, signal, computed, TemplateRef, 
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router'; // <- obté l'uuid del projecte i l'espai de la ruta

import { MatDialog } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { ApiService, ICategoryNode, ISearchConfiguration, ISearchConfigurationItem } from '../api.service';

export interface ICategoryTreeOptions {
  searchConfiguration?: boolean,
  categoryCreation?: boolean,
  categorySelection?: boolean // <- activat si s'habilita el searchConfiguration (s'ignora el valor passat)
}


interface ICategorySelection extends ICategoryNode {
  completed?: boolean,
}

class CategorySignals {
  readonly parent?:CategorySignals;
  readonly catSignal:WritableSignal<ICategorySelection>;
  public children:CategorySignals[] = [];
  public idx?:number

  constructor(node:ICategorySelection, parent?: CategorySignals, childIdx?:number) {
    this.parent = parent;
    this.idx = childIdx;
    this.catSignal = signal<ICategorySelection>(node);
    node.children?.forEach((child, n)=>{
      this.children.push(new CategorySignals(child, this, n));
    })
  }

  readonly partiallyComplete = computed(() => {
    if (!this.children) {
      return false;
    }
    // cal també marcar-ho parcial si algun dels fills és parcial!
    const partialOffspring = this.children.some((c)=>c.partiallyComplete());
    return partialOffspring || this.children.some((c)=>c.catSignal().completed) && !this.children.every((c)=>c.catSignal().completed);
  });

  update(completed: boolean, index?:number) {
    this.catSignal.update((cat:ICategorySelection)=>{
      cat.completed = completed;
      this.children.forEach((child, n)=>child.update(completed, n));
      if (index != undefined) {
        const updateAncestors = (pnt:CategorySignals)=>{
          const pstatus = pnt.children.map(cs=>cs.catSignal());
          pnt.catSignal.update((val)=>{
            val.completed = pstatus.every(c=>c.completed);
            if (pnt.parent) updateAncestors(pnt.parent)
            return val;
          });
        }
        // cal comprovar si tots els siblings són complets, i si cal, actualitzar el pare!
        updateAncestors(this.parent!);
      }
      return {...cat};
    });
  }
}

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrl: './category-tree.component.scss'
})
export class CategoryTreeComponent implements OnInit {

  @Input() categories: ICategoryNode[] = [];
  @Input() configs: ISearchConfiguration[] = [];
  @Input() options: ICategoryTreeOptions = { searchConfiguration:true, categoryCreation:true, categorySelection: true }
  @Input() selection?:string; // noms de categories separats per comes, p.ex: 'aparcament,circulacio,transit'
  @Output() change = new EventEmitter<{detail:string[]}>();

  signals: { [uuid:string]: CategorySignals } = {}; // <- controladors mapejats per l'uuid de l'ICategoryNode
  currentValue: string[] = [];

  dataSource: MatTreeNestedDataSource<ICategoryNode>;
  treeControl = new NestedTreeControl<ICategoryNode, number>(node=>node.children, {
    trackBy: (node: ICategoryNode) => node.id,
  });

  selectConfig = new FormControl();
  reset: boolean = false;

  constructor(
    private aroute: ActivatedRoute,
    private dialog: MatDialog,
    private api: ApiService,
  ) {
    this.dataSource = new MatTreeNestedDataSource<ICategoryNode>();
  }

  ngOnInit(): void {
    // Auxiliar per classificar els CategorySignals segons l'uuid cada ICategoryNode
    const classify = (elem:CategorySignals)=>{
      const cat = elem.catSignal();
      this.signals[cat.uuid] = elem;
      elem.children.forEach(child=>classify(child))
    }
    // crea i classifica el model de dades
    this.categories.map(c=>new CategorySignals(c)).forEach(cs=>classify(cs));
    // estableix la selecció inicial (si cal)
    // Primer deseleccionem tot (a partir dels root nodes de les categories)
    this.categories.forEach(c=>this.signals[c.uuid].update(false));
    if (this.selection) {
      const names = this.selection.split(',');
      const mustEnable = Object.keys(this.signals).map(uuid=>this.signals[uuid]).filter(ctl=>{
        return names.includes(ctl.catSignal().value);
      });
      mustEnable.forEach(ctl=>{
        if (ctl.parent) ctl.update(true, 1);
        else ctl.update(true)
      });
    }
    // inicicialitza l'arbre a partir de les categories rebudes
    this.dataSource.data = this.categories;
    // mostra l'arbre totalment expandit
    this.categories.forEach(c=>this.treeControl.expandDescendants(c));
  }

  // serveix per triar el template a mostrar segons el tipus de node
  hasChild = (_:number, node: ICategoryNode)=> Array.isArray(node.children) && !!node.children.length;
  
  // Emet els noms de les categories (només nodes fulla), eliminant repetits si cal
  // (l'alternativa seria fer servir els uuid).
  emitValue() {
    this.currentValue = Object.keys(this.signals).filter(
      uuid=>{
        const ctl = this.signals[uuid];
        return !ctl.children.length && ctl.catSignal().completed
      }
    ).map(
      uuid=>this.signals[uuid].catSignal().value as string
    ).sort().filter(
      // elimina repeticions
      (name, idx, ary)=>{
        if (!idx) return true;
        else return ary[idx-1]!=name;
      }
    );
    this.change.emit({ detail: this.currentValue });
  }

  configSelected({ value }: { value: Array<ISearchConfigurationItem> }) {
    // Primer deseleccionem tot (a partir dels root nodes de les categories)
    this.categories.forEach(c=>this.signals[c.uuid].update(false));

    // apliquem les categories del search configuration
    const v_ids = value.map(sci=>`${sci.value}`);
    Object.keys(this.signals).map(
      uuid=>[uuid, this.signals[uuid].catSignal().id]
    ).filter(
      ([uuid, id])=>v_ids.includes(`${id}`)
    ).forEach(([uuid, _])=>{
      const ctl = this.signals[uuid];
      if (ctl.parent) ctl.update(true, 0);
      else ctl.update(true)
    });
  }

  saveConfiguration(template:TemplateRef<any>) {
    console.log(`TODO: ${this.constructor.name} saveConfiguration`, this.currentValue);
    const dref = this.dialog.open<any,null,string>(template);
    dref.afterClosed().subscribe({
      next: (sname)=>{
        if (!sname) return;
        const { uuid, espai } = this.aroute.snapshot.params;
        const payload = {
          name: sname,
          items: this.currentValue.map(uuid=>{ return { key: 'category', value: uuid }; })
        }
        this.api.createSearchConfig(uuid, espai, payload).subscribe({
          next:(resp)=>{
            // cal afegir els ISearchConfigurationItems
            const confItems = this.currentValue.map(uuid=>{
              return { key: 'category', value: this.signals[uuid].catSignal().id } as ISearchConfigurationItem
            });
            this.configs.push({...resp, items:confItems} as ISearchConfiguration);
            this.configs = this.configs.filter(_=>true);
            this.selectConfig.setValue(confItems);
          }
        });
      }
    })
  }

  createCategory(template:TemplateRef<any>, parent?:ICategoryNode) {
    const dref = this.dialog.open<any,null,string>(template);
    dref.afterClosed().subscribe({
      next: (name)=>{
        if (!name) return;
        // obté l'uuid del projecte i l'espai de la ruta
        const { uuid, espai } = this.aroute.snapshot.params;
        this.api.createCategory(uuid, espai, name, parent?.uuid).pipe(
          this.api.uify("Creando categoría", "Un momento....")
        ).subscribe({
          next: ({ categories })=>{
            const [ cat ] = categories;
            // crea i classifica el model de dades
            if (parent) {
              const catIdx = this.signals[parent.uuid].children.length;
              this.signals[cat.uuid] = new CategorySignals(cat, this.signals[parent.uuid], catIdx);
              this.signals[parent.uuid].children[catIdx] = this.signals[cat.uuid];
              
              // cal trobar els parents per colocar la categoria on toqui
              const travFind:(node:CategorySignals, path?:string[])=>string[] = (node, path=[])=>{
                if (!node.parent) return path.reverse();
                else {
                  path.push(node.parent.catSignal().uuid);
                  return travFind(node.parent, path);
                }
              }
              const path = travFind(this.signals[cat.uuid]);

              let foundAncestor:ICategoryNode | undefined;
              path.forEach((segment)=>{
                if (foundAncestor) {
                  foundAncestor = foundAncestor.children?.find(node=>node.uuid==segment)
                } else {
                  foundAncestor = this.categories.find(node=>node.uuid==segment)
                }
              });

              if (foundAncestor?.children) {
                foundAncestor.children.push(cat);
              } else {
                foundAncestor!.children = [ cat ];
              }

            } else { // <- no parent, root category
              this.signals[cat.uuid] = new CategorySignals(cat);
              this.categories.push(cat);
            }
            // restableix l'arbre amb les noves les categories
            this.dataSource.data = this.categories;
            
            // força el refresc de l'arbre al template
            this.reset = true;
            setTimeout(()=>this.reset=false, 1);
          }
        })
      }
    });
  }

}
