import { Component, Input, OnInit, TemplateRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApiService, ITopic } from 'src/app/api.service';

import { Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

interface IBareTopic extends Partial<ITopic> {
  key:string;
  name:string;
  uuid:string;
}

@Component({
  selector: 'app-topic-editor',
  templateUrl: './topic-editor.component.html',
  styleUrl: './topic-editor.component.scss'
})
export class TopicEditorComponent implements OnInit, OnDestroy, OnChanges {
  
  @Input() topics: IBareTopic[] | undefined = []
  private _topics$ = new Subject<IBareTopic[]>(); 
  public topics$ = this._topics$.asObservable()
  .pipe(
    tap(nxt=>this.debug(nxt, 'topics refreshed:'))
  );

  constructor(
    private aroute: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
      this._topics$.next(this.topics || []);
  }
  ngOnDestroy(): void {
      this._topics$.complete();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const tch = changes['topics'];
    if (!tch.firstChange) {
      this._topics$.next(tch.currentValue);
    }
  }

  createTopic(template:TemplateRef<any>, edit?:IBareTopic) {
    const dref = this.dialog.open<any,IBareTopic|undefined,IBareTopic|undefined>(template, {
      data: edit
    });
    dref.afterClosed().subscribe({
      next: (payload)=>{
        if (!payload) return;
        const { uuid, espai } = this.aroute.snapshot.params;
        (edit?
          this.api.editTopic(uuid, espai, payload):
          this.api.createTopic(uuid, espai, payload)
        ).pipe(
          this.api.uify('Enviando...')
        ).subscribe({
          next:(resp)=>{
            console.log(`DEBUG ${this.constructor.name} createTopic/editTopic responds:`, resp);
            if (edit) {
              if (payload.deleted) {
                this.topics = this.topics?.filter(t=>t.key!=edit.key);
              } else {
                const elem = this.topics!.find(t=>t.key==edit.key);
                elem!.name = resp.name;
              }
            } else {
              this.topics!.push(resp);
            }
            this._topics$.next((<IBareTopic[]>[]).concat(this.topics||[]));
          }
        });
      }
    })
  }

  debug(sth:any, tag:string='') {
    if (environment.production) return;
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth)
  }

}
