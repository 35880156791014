<ng-template #menu_toggle>
  <!-- botó per mostrar el menú que només apareix quan el menú està amagat (o configuració handset) -->
  @if ( (isHandset$ | async) || !drawer.opened ) {
    <button style="margin-left:-.5em"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  }
</ng-template>

<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">

    <mat-toolbar style="color: var(--text-color)">
      <mat-toolbar-row style="justify-content: space-between;">
        <mat-icon aria-hidden="false" aria-label="useit|IA" 
          svgIcon="useitIA" style="width:112px;height:46px;margin:1em;color:var(--text-color)">
        </mat-icon>
        @if (!(isHandset$ | async)) {
          <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()">
            <mat-icon aria-label="Side nav toggle icon">menu_open</mat-icon>
          </button>
        }
      </mat-toolbar-row>
      <mat-toolbar-row>
        <mat-form-field appearance="outline" class="custom" style="width: 100%;margin-top: 2em;margin-right: 0;">
          <mat-label>Espacio</mat-label>
          <mat-select [formControl]="selectorEspacio" [compareWith]="compareSelectObjects" (selectionChange)="selectionChanged($event.value)">
            @for (espai of opcionsSelector; track espai.uuid) {
              <mat-option [value]="espai.uuid">{{espai.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-toolbar-row>
      <mat-toolbar-row style="height:fit-content;margin-top: 2em;">
         <h3 style="text-transform: uppercase;">{{pageMenu?.name}}</h3>
      </mat-toolbar-row>
    </mat-toolbar>

    <div style="height:calc(100% - 180px);display:flex;flex-direction:column;justify-content: space-between;">
      <mat-nav-list style="padding:1em;">
        @for (option of pageMenu?.options||[]; track option.title) {
          @if ( !!option.link ) {
            <mat-list-item [routerLink]="option.link" [state]="pageMenu?.state" routerLinkActive="active" ariaCurrentWhenActive="page">
              <mat-icon matListItemIcon aria-hidden="false" aria-label="icon" [fontIcon]="option.icon" *ngIf="option.icon"></mat-icon>
              <h2 matListItemTitle>{{option.title}}</h2>
            </mat-list-item>
          } @else  if ( !!option.subsections ) { <!-- toggle options -->
            <mat-expansion-panel style="box-shadow: none;" class="custom">
              <mat-expansion-panel-header style="padding-left: 0;">
                <mat-panel-title>
                  <mat-list-item >
                    <mat-icon matListItemIcon aria-hidden="false" aria-label="icon" [fontIcon]="option.icon" *ngIf="option.icon"></mat-icon>
                    <h2 matListItemTitle>{{option.title}}</h2>
                  </mat-list-item>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list>
                @for (sub of option.subsections||[]; track sub.title) {
                  <mat-list-item [routerLink]="sub.link" [state]="pageMenu?.state" routerLinkActive="active" ariaCurrentWhenActive="page">
                    <h2 matListItemTitle style="margin-left:56px">{{sub.title}}</h2>
                  </mat-list-item>
                }
              </mat-nav-list>
            </mat-expansion-panel>

          }
        }
      </mat-nav-list>
  
      <mat-action-list style="padding:0">
        <!-- TODO: control de sessió -->
        <mat-list-item (click)="debug('cerrar sesión')">
          <h2 matListItemTitle>Joan Martínez Pons</h2>
          <p matListItemLine>Cerrar sesión</p>
          <div matListItemMeta style="height: 100%;display:flex; align-items: center;">
            <button mat-icon-button (click)="debug('opcions?');$event.preventDefault();$event.stopPropagation();">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-action-list>
    </div>

  </mat-sidenav>

  <mat-sidenav-content style="background: var(--background-color); color: var(--text-color);">

    <mat-toolbar style="position: sticky; top: 0; z-index: 200;background-color: var(--lighter-bgcolor);color:var(--text-color);padding:0 16px;">
      @if(backLinks?.length) {
        <!-- toolbar dedicada per als breadcrumbs -->
        <mat-toolbar-row class="breadcrumbs">
          <ng-container *ngTemplateOutlet="menu_toggle"></ng-container>
          @for (blink of backLinks; track blink.link) {
            <button mat-button [routerLink]="blink.link" [state]="blink.state" color="primary">
              <mat-icon *ngIf="$first">arrow_back</mat-icon>
              {{blink.title}}
            </button>
            <span style="color:var(--opts-avatar-color)">&nbsp;/&nbsp;</span>
          }
        </mat-toolbar-row>
      } 
      <mat-toolbar-row>
        <ng-container *ngTemplateOutlet="!backLinks?menu_toggle:null"></ng-container>
        <!-- @if(backLinks?.length) {
          @for (blink of backLinks; track blink.link) {
            <button mat-button [routerLink]="blink.link" [state]="blink.state" color="primary">
              {{blink.title}}
            </button>
            <mat-icon>chevron_right</mat-icon>
          }
        }  -->
        <h1 style="font-size:28px">{{pageTitle}}</h1>

      </mat-toolbar-row>
      @if (pageSubtitle) {
        <mat-toolbar-row style="height:auto;margin-bottom:1em">
          <span>{{pageSubtitle}}</span>
        </mat-toolbar-row>
      }
    </mat-toolbar>
    

    <ng-content>
      <!-- 
        Contingut projectat des 
        del template de l'app.component 
        (router-outlet)
      -->
    </ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>
