import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes, ResolveFn, Router } from '@angular/router';
import { ApiService, IProjecte, ISpace } from './api.service'
import { ISubMenu, IBreadCrumb } from './nav-frame/nav-frame.component';
import { SignalService } from './nav-frame/signal.service';
import { Observable, forkJoin, of, tap, map, switchMap } from 'rxjs';

import { ProjectsComponent } from './projects/projects.component';
import { ProjectDetailComponent } from './projects/project-detail/project-detail.component';
import { SpaceDetailComponent } from './projects/project-detail/space-detail/space-detail.component';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { SourcesComponent } from './sources/sources.component';
import { SourcesItem } from './sources/sources-datasource';
import { FeedbackComponent } from './projects/project-detail/space-detail/feedback/feedback.component';
import { SparkChatComponent } from './spark-chat-component/spark-chat.component';
import { ModelDataComponent } from './projects/project-detail/space-detail/model-data/model-data.component';
import { ActivityLogComponent } from './projects/project-detail/space-detail/activity-log/activity-log.component';
import { ApiKeysComponent } from './projects/project-detail/space-detail/api-keys/api-keys.component';
import { DebugComponent } from './projects/project-detail/space-detail/debug/debug.component';
import { UsersComponent } from './users/users.component';
import { DocViewerComponent } from './doc-viewer/doc-viewer.component'
import { SpaceConfigurationComponent } from './projects/project-detail/space-detail/space-configuration/space-configuration.component';
import { PromptsComponent } from './projects/project-detail/space-detail/prompts/prompts.component';

/**
 * Tots els elements del routing es defineixen aquí, inclosos títols, subtítols i menús del NavFrameComponent.
 * Nota: Els strings literals s'haurien de canviar per "keys" per al ngx-translate, si es vol fer multi idioma.
 */

// Menú principal (per defecte)
const MENU_PPAL: ISubMenu = {
  name: "Menú principal",
  options: [
    { icon: 'dashboard', title:'Proyectos', link:'/proyectos' },
    { icon: 'people', title:'Usuarios', link:'/usuarios' },
  ]
}

// Genera el menú per la vista detall d'un projecte
const MENU_PROJ: (project:IProjecte)=>ISubMenu = (project)=>{
  const baselink =`/proyectos/${project.uuid}`;
  return {  
    name: "Menú de proyecto",
    options: [
      { icon: 'dashboard', title:'Espacios', link:`${baselink}/espacios` },
      { icon: 'people', title:'Usuarios', link:`${baselink}/usuarios` },
      { icon: 'flag', title:'Sources', link:`${baselink}/sources` },
    ],
    state: project
  };
}

// Genera el menú per la vista detall d'un projecte
const MENU_ESPAI: (uuid:string, espai:string, selmenu: { project:IProjecte, espais:ISpace[] })=>ISubMenu = (uuid, espai, selmenu)=>{
  const baselink = `/proyectos/${uuid}/espacios/${espai}`;
  return {  
    name: "Menú de espacio",
    options: [
      { icon: 'dashboard', title:'Información general', link:`${baselink}/general` },
      { icon: 'upload', title:'Subir datos', link:`${baselink}/upload` },
      { icon: 'flag', title:'Sources', link:`${baselink}/sources` },
      { icon: 'feedback', title:'Feedback', link:`${baselink}/feedback` },
      { icon: 'preview', title:'Preview', link:`${baselink}/preview` },
      { icon: 'settings', title:'Configuración', link:``, subsections:[
        { title: 'Prompts del espacio', link:`${baselink}/prompts` },
        { title: 'Configuración del espacio', link:`${baselink}/configuration` },
        { title: 'Datos del modelo', link:`${baselink}/data` },
        { title: 'Activity log', link:`${baselink}/activity` },
        { title: 'API keys', link:`${baselink}/keys` },
        { title: 'Debug', link:`${baselink}/debug` },
      ] },
    ],
    select: selmenu,
    selected: espai,
    state: selmenu
  };
}

// Auxiliar per actualitzar títol, subtítol, i si cal menú, del NavFrameComponent
// amb les dades estàtiques (data) de la ruta. retorna un "dummy" ResolveFn
// que resol a l'argument passat per paràmetre
function navFrameUpdater<T>(arg:T): ResolveFn<T> {
  return (route, state) => {
    const data = route.data;
    const navSS = inject(SignalService);
    navSS.title = data['pageTitle'];
    navSS.subtitle = data['subtitle'];
    navSS.backLinks = data['backLinks']; // <- cal per netejar (quan undefined)
    const menu = data['menu'];
    if (!!menu) navSS.subMenu =  menu;
    return arg;
  }
}


// Es rep l'IProjecte de l'estat del 'Navigation', així no calen crides a l'API.
// Si no existeix sí caldrà fer servir l'API
const projectDetailResolver: ResolveFn<IProjecte> = (route, state) => {
  const router = inject(Router);
  const api = inject(ApiService);
  const navSS = inject(SignalService);
  // Nota: en realitat l'objecte Navigation sempre hi serà (estem enmig del routing!).
  // Al queryOptions.state hi hem assignat el 'card' (IProjecte),
  // L'objecte 'state' es desa al Navigatior.history, per lo qual és segur navegar back/forward.
  const {proj} = router.getCurrentNavigation()?.extras.state as {proj:IProjecte};
  // NOTA: si es navega directament a un link (sense estat previ), llavors sí que cal l'API!!
  const proj$ = !!proj?of(proj):api.project(route.params['uuid']).pipe(
    api.uify('Obteniendo proyecto...') // <- mostro el loading per indicar l'us d'API
  );
  return proj$.pipe(
    tap(p=>{
      navSS.title = p.name;
      navSS.subtitle = route.data['subtitle'];
      navSS.backLinks = [{ title:"Proyectos", link:'/proyectos' }];
      navSS.subMenu = MENU_PROJ(p);
    }),
  );
};

// Es rep l'IProject actual i els seus ISpace[] de l'estat del 'Navigation', així no calen crides a l'API.
// Però si no hi ha estat previ de navegació és obligat obtenir les dades de l'API.
const espaiDetailResolver: ResolveFn<ISpace> = (route, state) => {
  const router = inject(Router);
  const api = inject(ApiService);
  const navSS = inject(SignalService);
  const uuidProj = route.params['uuid'];
  const idEspai = route.params['espai'];
  const extra = router.getCurrentNavigation()?.extras.state as { project:IProjecte, espais:ISpace[] };
  const extra$ = (extra?.project && extra?.espais)?of(extra):forkJoin({
    project: api.project(uuidProj),
    espais: api.espais(uuidProj)
  }).pipe(
    api.uify("Cargando...", "Obteniendo datos")
  ); 
  return extra$.pipe(
    map(({project, espais})=>{
      const espai = espais.find(e=>e.uuid == idEspai) as ISpace;
      navSS.title = route.data['pageTitle']; // espai.nom;
      navSS.subtitle = route.data['subtitle'];
      navSS.backLinks = [
        { title:"Proyectos", link:'/proyectos' }, 
        { title: project.name, link:`/proyectos/${uuidProj}/espacios`, state: project }
      ];
      navSS.subMenu = MENU_ESPAI(uuidProj, idEspai, { project:project, espais:espais });
      return espai;
    }),
  )
};

const sourcesItemResolver: ResolveFn<SourcesItem> = (route, state) => {
  const router = inject(Router);
  const api = inject(ApiService);
  const navSS = inject(SignalService);
  const uuid = route.params['uuid'];
  const source = route.params['source']; //<- uuid del document a obtenir
  const extra = router.getCurrentNavigation()?.extras.state as { project?:IProjecte, espais?:ISpace[], source:SourcesItem };

  // Nota: encadeno el espaiDetail Resolver per mantenir l'estat del NavFrame
  return (<Observable<ISpace>>espaiDetailResolver(route, state)).pipe(
    switchMap(espai=>{
      const extra$ = extra?of(extra):api.espaiSources(uuid, espai.uuid).pipe(
        map(sources=>{
          const found = sources.find(s=>s.uuid==source) as SourcesItem;
          return { source: found }
        }),
        api.uify( "Obteniendo fuente...." )
      );
      return extra$.pipe(
        map(state=>{
          navSS.subtitle = state.source.name || '';
          return state.source;
        }),
      );
    })
  );
}

const sourcesItemProjectResolver: ResolveFn<SourcesItem> = (route, state) => {
  const router = inject(Router);
  const api = inject(ApiService);
  const navSS = inject(SignalService);
  const uuid = route.params['uuid'];
  const source = route.params['source']; //<- uuid del document a obtenir
  const extra = router.getCurrentNavigation()?.extras.state as { source: SourcesItem };

  // Nota: encadeno el projectDetail Resolver per mantenir l'estat del NavFrame
  return (<Observable<IProjecte>>projectDetailResolver(route, state)).pipe(
    switchMap(espai=>{
      const extra$ = extra?of(extra):api.projectSources(uuid).pipe(
        map(sources=>{
          const found = sources.find(s=>s.uuid==source) as SourcesItem;
          return { source: found }
        }),
        api.uify( "Obteniendo fuente...." )
      );
      return extra$.pipe(
        map(state=>{
          navSS.subtitle = state.source.name || '';
          return state.source;
        }),
      );
    })
  );
}

/** 
 * The order of routes is important because the Router uses a first-match wins strategy when matching routes, 
 * so more specific routes should be placed above less specific routes. List routes with a static path first, 
 * followed by an empty path route, which matches the default route. 
 * The wildcard route comes last because it matches every URL and the Router selects it only if no other routes match first. 
 */
const routes: Routes = [
  // rutes estàtiques
  { 
    path: 'proyectos', component: ProjectsComponent,
    data: { 
      pageTitle: 'Proyectos', 
      subtitle: 'Selecciona un proyecto para ver más información',
      menu: MENU_PPAL
    },
    resolve: { dummy: navFrameUpdater(true) }
  },
  { 
    path: 'usuarios', component: UsersComponent, 
    data: { 
      pageTitle: 'Usuarios', 
      subtitle: '',
      menu: MENU_PPAL
    }, 
    resolve: { detail: navFrameUpdater(undefined) }
  },
  // ruta per defecte
  { path: '', redirectTo: '/proyectos', pathMatch: 'full' },
  // rutes dinàmiques, de més específiques a menys
  { 
    path: 'proyectos/:uuid/espacios/:espai/general', component: SpaceDetailComponent, 
    data: { pageTitle: 'Información general'},
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/upload', component: DataUploadComponent,
    data: { pageTitle: 'Subir datos' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/sources/:source', component:  /*SourcesComponent, /*/DocViewerComponent,
    data: { pageTitle: 'DocViewer' }, 
    resolve: { 
      source: sourcesItemResolver // <- composat amb l'espaiDetailResolver per si cal restablir l'estat del NavFrameComponent
    }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/sources', component: SourcesComponent,
    data: { pageTitle: 'Sources' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/feedback', component: FeedbackComponent,
    data: { pageTitle: 'Feedback' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/preview', component: SparkChatComponent,// PreviewComponent,
    data: { pageTitle: 'Preview' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/configuration', component: SpaceConfigurationComponent,
    data: { pageTitle: 'Configuración', subtitle: 'Configuración de espacio' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/prompts', component: PromptsComponent,
    data: { pageTitle: 'Prompts', subtitle: 'Prompts del espacio' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/data', component: ModelDataComponent,
    data: { pageTitle: 'Configuración', subtitle: 'Datos del modelo' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/activity', component: ActivityLogComponent,
    data: { pageTitle: 'Configuración', subtitle: 'logs' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/keys', component: ApiKeysComponent,
    data: { pageTitle: 'Configuración', subtitle: 'API keys' }, 
    resolve: { detail: espaiDetailResolver }
  },
  {
    path:'proyectos/:uuid/espacios/:espai/debug', component: DebugComponent,
    data: { pageTitle: 'Configuración', subtitle: 'Debug' }, 
    resolve: { detail: espaiDetailResolver }
  },
  { 
    path: 'proyectos/:uuid/espacios', component: ProjectDetailComponent, 
    data: { subtitle: 'Selecciona un espacio para ver más información'},
    resolve: { detail: projectDetailResolver }
  },
  {
    path: 'proyectos/:uuid/usuarios', component: UsersComponent, 
    data: { subtitle: 'Usuarios del proyecto'},
    resolve: { detail: projectDetailResolver }
  },
  {
    path:'proyectos/:uuid/sources/:source', component:  /*SourcesComponent, /*/DocViewerComponent,
    data: { pageTitle: 'DocViewer' }, 
    resolve: { 
      source: sourcesItemProjectResolver // <- composat amb el projectDetailResolver per si cal restablir l'estat del NavFrameComponent
    }
  },
  {
    path: 'proyectos/:uuid/sources', component: SourcesComponent, 
    data: { subtitle: 'Fuentes del proyecto'},
    resolve: { projecte: projectDetailResolver }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
