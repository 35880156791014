<h2 mat-dialog-title>Autenticación</h2>
<mat-dialog-content>
    <form style="display:flex; flex-direction:column">
        <mat-form-field>
            <mat-label>Usuario</mat-label>
            <input matInput type="text" name="usuari" autocomplete="username" required #user>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Contraseña</mat-label>
            <input matInput type="password" name="pass" autocomplete="current-password" required #pass>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: flex-end">
    <button mat-flat-button color="accent" (click)="validateLogin(user.value, pass.value)">Enviar</button>
</mat-dialog-actions>
