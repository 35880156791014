<div class="filters">
    <mat-form-field>
        <mat-label>Prompt key</mat-label>
        <input matInput type="text" [(ngModel)]="promptKeySearchText" (ngModelChange)="filterPrompts()">
    </mat-form-field>
</div>

<div class="prompts">
    @for (prompt of filteredPrompts; track $index) {
        <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>{{prompt?.key}}</mat-card-title>
              <mat-card-subtitle>{{prompt.content.slice(0,50)}} ...</mat-card-subtitle>
            </mat-card-header>
            @if (prompt.expanded) {
                <mat-card-content>
                    <mat-form-field>
                        <mat-label>Prompt</mat-label>
                        <textarea matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"
                                    [(ngModel)]="prompt.content"
                                    >
                        </textarea>
                    </mat-form-field>
                </mat-card-content>
                <div class="submit">
                    <button mat-fab extended (click)="updatePrompt(prompt)"> 
                        <mat-icon>done</mat-icon>
                        Aceptar
                    </button>
                    <button mat-fab extended (click)="ngOnInit()"> 
                        <mat-icon>restart_alt</mat-icon>
                        Reiniciar
                    </button>
                </div>
            }
            @if (!prompt.expanded) {
                <mat-card-actions>
                    <button mat-button (click)="prompt.expanded = !prompt.expanded">Muestra mas...</button>
                  </mat-card-actions>
            }
            @else {
                <mat-card-actions>
                    <button mat-button (click)="prompt.expanded = !prompt.expanded">Muestra menos...</button>
                </mat-card-actions>
            }
        </mat-card>
    }
</div>