<!-- template pel diàleg de crear preguntes -->
<ng-template #qinput>
    <h3 mat-dialog-title>Nueva pregunta:</h3>
    <div mat-dialog-content style="display:flex;flex-direction:column;">
        <mat-form-field>
            <mat-label>Pregunta</mat-label>
            <input matInput required #qquest>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Topic</mat-label>
            <mat-select #qtopic required>
                @for (topic of topics; track topic.key) {
                    <mat-option [value]="topic.key">{{topic.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="display:flex;justify-content: flex-end;">
        <button mat-button [mat-dialog-close]="{ question: qquest.value, topic: qtopic.value }" [disabled]="!qquest.value||!qtopic.value">
            <mat-icon>add</mat-icon>
            Crear
        </button>
    </div>
</ng-template>

<mat-table class="full-width-table" matSort aria-label="Refinery" style="background-color:  var(--background-color)"
    #questionTable="matTable" #questionSort="matSort">
        
    <!-- search column -->
    <ng-container matColumnDef="search">
        <mat-header-cell *matHeaderCellDef style="flex-basis:40%; border-bottom:none; padding: 1em 0;padding-left:0">
        <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 2 0;text-transform: none;--mat-mdc-form-field-label-offset-x: 2em;">
            <mat-label>Search question</mat-label>
            <mat-icon matTextPrefix style="position:relative;top:+.33em;">search</mat-icon>
            <input matInput placeholder="..." [formControl]="questionSearchControl" type="search"><!--TODO: observable search-->
        </mat-form-field>
        </mat-header-cell>
    </ng-container>

    <!-- topic filter -->
    <ng-container matColumnDef="topic-filter">
        <mat-header-cell *matHeaderCellDef style="flex-basis:15%; border-bottom:none; padding: 1em 0">
          <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
            <mat-label>Topic</mat-label>
            <mat-select [formControl]="topicSelectionControl" multiple>
                @for (filter of topics; track filter.key) {
                    <mat-option [value]="filter.key">{{filter.name}}</mat-option>
                }
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
    </ng-container>

    <!-- paginator -->
    <ng-container matColumnDef="paginator">
        <mat-header-cell *matHeaderCellDef style="flex-basis:35%; border-bottom:none; padding: 1em 0">
        <mat-paginator #questionPaginator="matPaginator" style="background-color: transparent;"
            [length]="questionsDataSource.data.length"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page">
        </mat-paginator>
        </mat-header-cell>
    </ng-container>

    <!-- Add button -->
    <ng-container matColumnDef="add">
        <mat-header-cell *matHeaderCellDef style="flex-basis:10%; border-bottom:none; padding: 1em 0">
        <button mat-flat-button color="primary" (click)="createQ(qinput)"
            style="height: 54px; border-radius:27px; padding: 0 1.5em; flex:auto 0 0">
            <mat-icon>add</mat-icon>
            Añadir pregunta
        </button>
        </mat-header-cell>
    </ng-container>

    <!-- Questions Column -->
    <ng-container matColumnDef="questions">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Question</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.question}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Question</mat-footer-cell>
    </ng-container>

    <!-- Topic Column -->
    <ng-container matColumnDef="topic">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Topic</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.topic.name}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Topic</mat-footer-cell>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.created_at | date:'shortDate':'':'es'}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Date</mat-footer-cell>
    </ng-container>

    <!-- Sources Column -->
    <ng-container matColumnDef="sources">
        <mat-header-cell *matHeaderCellDef>Related sources</mat-header-cell>
        <mat-cell *matCellDef="let row"><!--{{row.updated_at | date:'mediumDate':'':'es-ES'}}-->TODO</mat-cell>
        <mat-footer-cell *matFooterCellDef>Related sources</mat-footer-cell>
    </ng-container>
    

    <mat-header-row *matHeaderRowDef="['search', 'topic-filter', 'paginator', 'add'].concat(displayedQuestionColumns); sticky: true;" 
        style="text-transform: uppercase;background-color: var(--background-color);flex-wrap:wrap;">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedQuestionColumns;">
        <!-- (click)="row.selected=!row.selected;debug(row, 'row clicked')" matRipple--> <!--[routerLink]="[row.uuid]" [state]="{ source: row }"-->
    </mat-row>
    <mat-footer-row *matFooterRowDef="displayedQuestionColumns; sticky: true;"
        style="text-transform: uppercase;background-color: var(--background-color);">
    </mat-footer-row>
</mat-table>