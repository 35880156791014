

  @switch (source.type) {
    @case('pdf') {
      <!-- style="display:block; width: 100%; height: calc(100% - 108px);"  -->
      <ngx-extended-pdf-viewer style="display:block; width: 70vw; height: 750px"
       [src]="signedURL || ''">
      </ngx-extended-pdf-viewer>
    }
    @case('txt') {
      <pre style="white-space: pre-wrap; padding: 32px">{{signedURL | fetchtxt | async}}</pre>
    }
    @default() {
      <!-- style="width: 100%; height: calc(100% - 108px);" -->
      <object 
        [data]="signedURL | sanitize" [type]="fileType || 'text/plain'">
      </object>
    }
  }


