import { AfterViewInit, Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SessionsDataSource } from './sessions-datasource';
import { ApiService, ISession, ISpace } from 'src/app/api.service';
import { environment } from 'src/environments/environment';
import { Subscription, combineLatest, startWith, debounceTime } from 'rxjs';

interface ISessionTS extends ISession {
  timestamp: number;
}

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrl: './sessions.component.scss'
})
export class SessionsComponent implements AfterViewInit, OnDestroy {

  private _sessions: ISessionTS[] = [];
  private _sub: Subscription;

  public topics: string[] = [];
  get sessionsTotals() {
    return this._sessions.length
  }
  get missatgesTotals() {
    return this._sessions.map(s=>s.messages).reduce((a,b)=>a+b, 0);
  }

  @Input() uuid: string; // <- uuid del projecte (param url) 
  @Input() espai: string; // <- uuid de l'espai
  @Input() set detail(dtl:ISpace) {
    this.api.espaiSessions(this.uuid, dtl.uuid).pipe(
      this.api.uify('Obteniendo sesiones...', dtl.name)
    ).subscribe({
      next: (nxt)=>{
        this._sessions = this.dataSource.data = nxt.map(e=>{ (<any>e).timestamp = new Date(e.start_date).getTime(); return e as ISessionTS});
        this._sessions.sort((a,b)=>b.timestamp-a.timestamp);
        this.topics = this._sessions.map(s=>s.topics).reduce((a,b)=>a.concat(b), []).sort().filter(
          (elem, idx, ary)=>!idx||ary[idx-1]!=elem // <- elimina duplicats
        )
        //this.debug(nxt, 'got sessions:');
      }
    });
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<ISession>;
  dataSource = new SessionsDataSource();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['sessio', 'data', 'missatges', 'tags'];
  searchControl = new FormControl('');
  topicSelection = new FormControl('');

  constructor(
    private api: ApiService
  ) {
    // implementa el filtrat
    this._sub = combineLatest([
      this.searchControl.valueChanges.pipe(startWith(''), debounceTime(350)),
      this.topicSelection.valueChanges.pipe(startWith([]))
    ]).subscribe({
      next: ([search, topics])=>{
        let retval = this._sessions || [];
        if (Array.isArray(topics) && topics.length) {
          retval = retval.filter(sess=>!!topics.find(topic=>sess.topics.includes(topic)));
        }
        if (search?.length) {
          const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
          retval = retval.filter(sess=>!!sess.session?.match(regexp))
        }
        this.dataSource.data = retval;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    //this.debug(this.dataSource.data, 'ngAfterViewInit')
  }

  ngOnDestroy(): void {
      this._sub.unsubscribe();
  }

  debug(sth:any, tag="") {
    if (environment.production) return;
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth);
  }
}
