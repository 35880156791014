import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import { IProjecte, ISpace } from '../api.service';
import { SignalService } from './signal.service';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, filter, tap } from 'rxjs/operators';

type select_t = { uuid: string, name:string };// { id: number | string, nom:string };

export interface ISubMenu {
  name: string,
  options: IMenuOption[],
  select?: { project:IProjecte, espais:ISpace[] } // <- per generar el selector d'espais
  selected?: number | string // <- espai seleccionat
  state?: any // <- estat passat amb els links del menú
}

export interface IMenuOption {
  icon:string, title:string, link:string, subsections?: {title: string, link: string} []
}

// amb els breadcrumbs hem de pder pasar-hi "estat"
export interface IBreadCrumb {
  title:string, link:string, state?: any
}

@Component({
  selector: 'app-nav-frame',
  templateUrl: './nav-frame.component.html',
  styleUrl: './nav-frame.component.scss'
})
export class NavFrameComponent implements OnDestroy {

  // controla si el menú ha de ser flotant o incrustat (sidenav-content, veure template)
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([
    Breakpoints.Handset, Breakpoints.Small, Breakpoints.XSmall
  ]).pipe(
    map(result => result.matches),
    shareReplay()
  );

  pageTitle?: string;
  pageSubtitle?: string;
  pageMenu?:ISubMenu;
  backLinks?:IBreadCrumb[];
  selectorEspacio = new FormControl('');
  opcionsSelector: select_t[] = [];

  private _sub:Subscription;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private ss: SignalService,
  ) {
    // d'entrada no hi ha espais per triar
    this.selectorEspacio.disable();

    // Actualitza títol, subtítol i menú amb events 
    // depenents de la ruta, definits a l'app-routing.module.ts
    this._sub = this.ss.title$.subscribe({ next: tit=>this.pageTitle = tit });
    this._sub.add( 
      this.ss.subtitle$.subscribe({ next: subt=>this.pageSubtitle = subt })
    );
    this._sub.add(
      this.ss.backLinks$.subscribe({ next: links=>this.backLinks = links})
    );
    this._sub.add(
      this.ss.subMenu$.pipe(tap({
        next: menu=>{
          if (menu.select) {
            this.selectorEspacio.enable();
            this.opcionsSelector = menu.select.espais;
            this.selectorEspacio.setValue(`${menu.selected}`);
          } else {
            this.opcionsSelector = [];
            this.selectorEspacio.disable();
            this.selectorEspacio.setValue(null);
          }
        }
      })).subscribe({ next: menu=>this.pageMenu = menu})
    );
  }

  compareSelectObjects(a: string, b: string ) {
    return a == b; //`${a}` == `${b}`;
  }

  selectionChanged(sel: string) {
    this.router.navigate(['/proyectos', this.pageMenu?.select?.project.uuid,'espacios', sel, 'general' ], {
      state: this.pageMenu?.state
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  debug(sth:any, tag='') {
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth)
  }
}
