import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ApiService, IPrompt } from 'src/app/api.service';

@Component({
  selector: 'app-prompts',
  standalone: false,
  templateUrl: './prompts.component.html',
  styleUrl: './prompts.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PromptsComponent {

  @Input() uuid?: string;
  @Input() espai?:string;
  prompts: IPrompt[] = [];
  filteredPrompts: IPrompt[] = [];
  promptKeySearchText: string;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.fetchPrompts();
  }

  private fetchPrompts() {
    this.apiService.getSpacePrompts(this.uuid!!, this.espai!!).subscribe({
      next: (success) => {
        this.prompts = success;
        for (let prompt of this.prompts) {
          prompt.expanded = false;
        }
        this.filteredPrompts = this.prompts;
      },
    });
  }

  updatePrompt(prompt: IPrompt) {
    this.apiService.editSpacePrompt(this.uuid!!, this.espai!!, prompt).subscribe({
      next: (success) => {
        this.ngOnInit();
      },
    });
  }
  
  filterPrompts() {
    this.filteredPrompts = this.prompts.filter((prompt) => prompt.key.includes(this.promptKeySearchText));
  }

}
