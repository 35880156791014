
<mat-tab-group>
    <!-- <mat-tab label="Modelo">TODO</mat-tab> -->

    <mat-tab label="Modelo semántico">
        <app-category-tree *ngIf="!!categories" 
            style="display:block;padding: 24px;background-color: var(--background-color);"
            [categories]="categories" 
            [options]="{ categoryCreation:true }">
        </app-category-tree>
    </mat-tab>
    
    <mat-tab label="Configuraciones de búsqueda">
        <app-category-tree *ngIf="!!categories" 
            style="display:block;padding: 24px;background-color: var(--background-color);"
            [categories]="categories" [configs]="searchConfigs" 
            [options]="{ searchConfiguration: true }">
        </app-category-tree>
    </mat-tab>
    
    <mat-tab label="Temas">
        <app-topic-editor [topics]="topics"></app-topic-editor>
    </mat-tab>
    
    <mat-tab label="Preguntas">
        <app-question-editor
            [questions]="questions" 
            [topics]="topics">
        </app-question-editor>
    </mat-tab>
</mat-tab-group>
