enum IndexingLanguages {
    English = 'en',
    Spanish = 'es',
    Catalan = 'ca',
    French = 'fr',
    German = 'de',
}

export function getIndexingLanguageWithLocale(locale: string): IndexingLanguages {
    switch (locale) {
        case 'en':
            return IndexingLanguages.English;
        case 'es':
            return IndexingLanguages.Spanish;
        case 'ca':
            return IndexingLanguages.Catalan;
        case 'fr':
            return IndexingLanguages.French;
        case 'de':
            return IndexingLanguages.German;
        default:
            return IndexingLanguages.English;
    }
}

export function getLanguageNameWithLocale(locale: string): string {
    const localeIl = getIndexingLanguageWithLocale(locale);
    switch (localeIl) {
        case IndexingLanguages.English:
            return 'English';
        case IndexingLanguages.Spanish:
            return 'Spanish';
        case IndexingLanguages.Catalan:
            return 'Catalan';
        case IndexingLanguages.French:
            return 'French';
        case IndexingLanguages.German:
            return 'German';
    }
}

export function getLanguageName(language: IndexingLanguages): string {
    switch (language) {
        case IndexingLanguages.English:
            return 'English';
        case IndexingLanguages.Spanish:
            return 'Spanish';
        case IndexingLanguages.Catalan:
            return 'Catalan';
        case IndexingLanguages.French:
            return 'French';
        case IndexingLanguages.German:
            return 'German';
    }
}