<!-- template pel diàleg de crear categories -->
<ng-template #catinput>
    <h3 mat-dialog-title>Nueva categoría:</h3>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput required #catname>
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="display:flex;justify-content: flex-end;">
        <button mat-button [mat-dialog-close]="catname.value" [disabled]="!catname.value">
            <mat-icon>add</mat-icon>
            Crear
        </button>
    </div>
</ng-template>

<!-- template pel diàleg que anomena noves configuracions de cerca -->
<ng-template #searchname>
    <h3 mat-dialog-title>Nueva configuración de búsqueda:</h3>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput required #sname>
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="display:flex;justify-content: flex-end;">
        <button mat-button [mat-dialog-close]="sname.value" [disabled]="!sname.value">
            <mat-icon>add</mat-icon>
            Crear
        </button>
    </div>
</ng-template>

<mat-toolbar *ngIf="options.searchConfiguration || options.categoryCreation" style="justify-content: space-between;">

    <ng-container *ngIf="options.searchConfiguration; else title">
        <mat-form-field appearance="outline" class="custom">
            <mat-label>Search configuration</mat-label>
            <mat-select (selectionChange)="configSelected($event)" [formControl]="selectConfig" #cfg_s>
                @for (config of configs; track config.id) {
                    <mat-option [value]="config.items">{{config.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <span style="flex: 50% 5 0"></span>
        <button mat-icon-button (click)="configSelected({value:[]}); cfg_s.value=null" 
            [disabled]="!cfg_s.value" aria-label="reset">
            <mat-icon>replay</mat-icon>
        </button>
        <button mat-icon-button (click)="saveConfiguration(searchname)" 
            [disabled]="cfg_s.value || !currentValue.length" aria-label="reset">
            <mat-icon>upload</mat-icon><!--publish-->
        </button>
    </ng-container>
    <ng-template #title><span style="font-weight:600;font-size:large;">Categorías</span><span style="flex: 50% 5 0"></span></ng-template>

    <button *ngIf="options.categoryCreation" mat-button (click)="createCategory(catinput)" color="primary"
        [disabled]="selectConfig.value" aria-label="add category">
        <mat-icon>add_circle</mat-icon>
        Categoría raíz
    </button>
</mat-toolbar>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree" 
    style="margin: 1em 0; padding: 1em;--mat-tree-container-color: var(--opts-avatar-bgcolor);" *ngIf="!reset">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
    <mat-tree-node *matTreeNodeDef="let node" class="mat-tree-node">
        <ng-container *ngIf="options.categorySelection || options.searchConfiguration; else nval">
            <mat-checkbox *ngIf="signals[node.uuid] as controller" [disabled]="!!selectConfig.value"
                [checked]="controller.catSignal().completed"
                [indeterminate]="controller.partiallyComplete()"
                (change)="controller.update($event.checked, controller.idx);emitValue()">
                {{node.value}}
            </mat-checkbox>
        </ng-container>
        <ng-template #nval>{{node.value}}</ng-template>
        <button *ngIf="options.categoryCreation && !selectConfig.value" 
            mat-icon-button (click)="createCategory(catinput, node)" 
            color="primary" aria-label="add category">
            <mat-icon>add_circle_outline</mat-icon>
        </button>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node
        *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node" *ngIf="signals[node.uuid] as controller">
            <button mat-icon-button matTreeNodeToggle [matTreeNodeToggleRecursive]="true" [attr.aria-label]="'Toggle ' + node.value">
                <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <ng-container *ngIf="options.categorySelection || options.searchConfiguration; else nodval">
                <mat-checkbox [checked]="controller.catSignal().completed" [disabled]="!!selectConfig.value"
                    [indeterminate]="controller.partiallyComplete()"
                    (change)="controller.update($event.checked, controller.idx);emitValue()">
                    {{node.value}}
                </mat-checkbox>
            </ng-container>
            <ng-template #nodval>{{node.value}}</ng-template>
            <button *ngIf="options.categoryCreation && !selectConfig.value"
                mat-icon-button (click)="createCategory(catinput, node)" 
                color="primary" aria-label="add category">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree>