import { Component } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ApiService } from '../api.service';

/* cms : QtPw5BRtsQ5YJE4zGjZ37h */

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatFormFieldModule, MatInputModule, MatButtonModule, 
    MatDialogTitle, MatDialogContent, MatDialogActions
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(
    private dialogRef: MatDialogRef<LoginComponent>,
    private api: ApiService,
  ) {}

  validateLogin(user:string, pass: string) {
    this.api.login(user, pass).pipe(
      this.api.uify('Un momento...')
    ).subscribe({
      next:({error, msg, user})=>{
        if (!!error) console.log(`ERROR ${this.constructor.name}:`, msg);
        else this.dialogRef.close()
      },
    })
  }

}
